import React from "react";
import Login, {btn_login_extra, btn_login_main, JWT} from "./google_sso_login";
import {GoogleOAuthProvider} from "@react-oauth/google";

export default class Log_in_label extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menu_open: false};

        if(this.props.id === btn_login_main) //only the main login button (on the top) should convert to the avatar
            window.GoogleLogin = this;
    }

    render() {
        return (
            <div>
                {!JWT ?
                    <div className="div_vertical"><label className="label_warning">Sign in with Gmail and get started</label><br/>

                        <div className="div_horizontal" style={{justifyContent: "space-between", alignItems: "center"}}>
                            <GoogleOAuthProvider
                                clientId="554535965820-bom7kbsbkmjb87on99fr049s3tnq9hvh.apps.googleusercontent.com">
                                <Login id={btn_login_extra}/>
                            </GoogleOAuthProvider>
                        </div>
                    </div>
                    :
                    <div/>
                }
            </div>
        )
    }
}
