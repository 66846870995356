import {radial_btn_names} from "./radial_button";
import {btn_menu_user_id, div_menu_user_id} from "../menu/menu_user";
import {btn_menu_mode_id, div_menu_mode_id} from "../menu/menu_mode";
import {menu_autocomplete_id} from "../elements/autocmoplete";

export function click_outside_handler() {

    document.addEventListener('click', (event) => {

        // Radial buttons
        let control;
        for (let i = 0; i < radial_btn_names.length; i++) {
            control = document.getElementById("radial_" + radial_btn_names[i]);
            if (clicked_outside_control(control, event))
                control.className = "radial";
        }

        // User menu
        control = document.getElementById(btn_menu_user_id);
        if(clicked_outside_control(control, event)) {
            //clicked not on the avatar button
            control = document.getElementById(div_menu_user_id);
            // log("control: " + control);
            if (window.Menu_User.state.menu_open && clicked_outside_control(control, event)) {
                //clicked outside the open menu
                window.Menu_User.setState({menu_open: false});
            }
        }

        // Mode menu
        control = document.getElementById(btn_menu_mode_id);
        if(clicked_outside_control(control, event)) {
            //clicked not on the button
            control = document.getElementById(div_menu_mode_id);
            // log("control: " + control);
            if (window.Menu_Mode.state.menu_open && clicked_outside_control(control, event)) {
                //clicked outside the open menu
                window.Menu_Mode.setState({menu_open: false});
            }
        }

        // Autocomplete menu
        control = document.getElementById(menu_autocomplete_id);
        if(clicked_outside_control(control, event)) {
            //clicked not on the menu
            if(control)
                control.className = "dropdown-menu inactive"
        }
    })

}

function clicked_outside_control(control, event){
    const clicked_on_control = event.composedPath().includes(control);
    if (clicked_on_control)
        return false;
    else
        return true;
}
