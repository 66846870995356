import {request_and_validate} from "./request";
import {update_Controls, word_type} from "../main_page";
import {
    add_tts_parameters_to_json,
    addQuotes,
    JSON_to_FormData,
    log,
    request_types,
    setDisplayValue,
} from "./helpers";
import {app_state, max_ai_response_length, settings} from "../sub_pages/settings";
import {set_Text_to_Translate} from "./translation_block";
import {guest_says, playSound, setVoices} from "./tts";
import {phrase_was_finished} from "./podcast";
import {ask_questions, debug_mode} from "../app_settings";
import axios from "axios";
import {JWT} from "./google_sso_login";
import {server_URL} from "../app_settings";
import {askAI_voice2} from "./request";
import {is_iOS} from "./helpers"
import { balloons } from "balloons-js";

export const open_ai_chat_model = "gpt-4o-mini";
// gpt-4o-mini = $0.6/1M output tokens
// gpt-4o = $15/1M output tokens

// ChatGPT playground - https://platform.openai.com/playground

// question = text question of FormData with audio
export async function ask_AI_and_pronounce(question, control_media = true){
    log("ask_ai_and_pronounce");

    app_state.audio_suspended = false;
    app_state.AI_response_was_loaded = false;
    // startWatchdog();

    if(control_media) {
        // app_state.state = ;
        update_Controls(app_state.states.story_ongoing);
    }

    // if(app_state.audio_suspended) {
    //     log("Audio was suppressed by Safari, play it")
    //     await playSound(app_state.AI_response_audio)
    //     return;
    // }

    setDisplayValue("spr_ongoing_podcast_center", "inline-block");

    await setVoices();
    // log(open_ai_chat_model);

    let formData = null;
    let audio_question = question instanceof FormData;
    
    if (audio_question)
        formData = question;
    else {
        let metadata = {
            question: question,
            model: open_ai_chat_model,
            request_type: request_types.text_to_audio,
            messages: [
                // { "role": "system", "content": "You are a helpful assistant." },
                { "role": "system", "content": "You are an language teacher." },
                { "role": "system", "content": "Please provide concise responses not longer than " + max_ai_response_length + " symbols." },
              ],
              max_response_length: max_ai_response_length,
        };
        metadata = add_tts_parameters_to_json(metadata);
        formData = JSON_to_FormData(metadata)
    }

    const response = await request_and_validate("ask_ai", 'POST', formData);
    app_state.AI_response_was_loaded = true;

    const response_metadata = response.headers['x-metadata'];
    log("response_metadata", response_metadata)
    let response_json = JSON.parse(JSON.parse(response_metadata));
    app_state.AI_response = await clean_response(response_json["AI_response"], true);
    if(audio_question)
        app_state.last_user_request = await clean_response(response_json["User_request"], true);
    else
        app_state.last_user_request = null;
    set_Text_to_Translate(app_state.AI_response, settings.auto_translation.value, app_state.last_user_request);

    setDisplayValue("spr_ongoing_podcast_center", "none");

    const mp3 = new Blob([response.data], {type: 'audio/mp3'});
    app_state.AI_response_audio = URL.createObjectURL(mp3);

    if(!is_iOS()) await playSound(app_state.AI_response_audio);

    if(control_media) {
        if(phrase_was_finished())
            app_state.state = app_state.states.none; // The story has been fully played
        update_Controls();
    }
}

export async function give_example(){
    log("give_example");

    const ai_request = "Please tell me a shocking fact with the " + word_type + " " + addQuotes(app_state.expression);
    await ask_AI_and_pronounce(ai_request);
}

export async function clean_response(response, trim_response){
    // let result = response.data['choices'][0]['text'];
    // log("result:" + response);
    let result = response;
    if(trim_response) result = result.trim();

    result = result.replace(/[%*#]/g, ''); // remove symbols: *, %, #

    // if(!ask_questions) result = result.replace(/(\r\n|\n|\r)/gm, ""); // remove \n symbol

    // log(response);
    // log(result);
    return result;
}