import {log} from "./helpers";

export let load_control = {
    settings:{name:"settings",last_load_time:null,min_load_interval: 60},
    vocabulary:{name:"vocabulary",last_load_time:null,min_load_interval: 5*60}
}


export function makes_sense_to_load_again(setting_name){
    // log("load_control.settings.last_load_time: " + load_control.settings.last_load_time)
    switch (setting_name) {
        case load_control.settings.name:
            return makes_sense_to_load(load_control.settings.last_load_time, load_control.settings.min_load_interval);
            break;

        case load_control.vocabulary.name:
            return makes_sense_to_load(load_control.vocabulary.last_load_time, load_control.vocabulary.min_load_interval);
            break;
    }
}

function makes_sense_to_load(last_load_time, min_load_interval) {
    if (!last_load_time) return true; // first load

    const time_left = (Date.now() - last_load_time) / 1000; // sec
    if (time_left < min_load_interval)
        return false;
    else
        return true;
}

export function load_control_reset(){
    log("load_control_reset");
    load_control.settings.last_load_time = null;
    load_control.vocabulary.last_load_time = null;
}
