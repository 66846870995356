import React from 'react';
import menu from "../../images/menu_600_v2.png";
import {btnOpenMenu_onClick} from "../sub_pages/menu";

export default class Menu_Button extends React.Component {

    componentDidMount() {
    }

    render() {
        return (
            <div className="div_menu">
                <img src={menu} className="icon_menu" onClick={btnOpenMenu_onClick}/>
            </div>
        )
    }
}