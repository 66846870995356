import { request_and_validate, sendRequest } from "./request";
import { word_type } from "../main_page";
import { addQuotes, delay, is_iOS, JSON_to_FormData, log, getRandomNumber } from "./helpers";
import { app_state, learning_languages, max_ai_response_length, settings } from "../sub_pages/settings";
import { start_screen_lock, stop_screen_lock } from "./screen_lock";
import app from "../../App";
import { podcast_is_paused_or_pausing, podcast_or_story_is_pausing } from "./podcast";
import { animation } from "./talking_head";
import { debug_mode } from "../app_settings";
import { turn_off_mic, turn_on_mic } from "./AudioRecorder";

// Voices
//AWS voices - https://docs.aws.amazon.com/polly/latest/dg/voicelist.html
//Google voices - https://cloud.google.com/text-to-speech/docs/voices
const Google_male_voices = ["en-US-Wavenet-A", "en-US-Wavenet-B", "en-US-Wavenet-D", "en-US-Wavenet-I", "en-US-Wavenet-J"]
const Google_female_voices = ["en-US-Neural2-F", "en-US-Wavenet-F", "en-US-Standard-H", "en-US-Neural2-H"]; //"en-US-Wavenet-C", "en-US-Wavenet-G", "en-US-Standard-G" // Favorite - en-US-Wavenet-F, en-US-Standard-H
const AWS_male_voices = ["Joey", "Matthew", "Kevin", "Justin"] // Kevin, Justin - kids
const AWS_female_voices = ["Salli", "Kimberly", "Ivy"] // "Kendra", "Joanna", // Favorite - Salli, Joanna; Ivy - kid

function get_voice_pairs() {
    let pairs;
    switch (settings.learning_language.value) {
        // [host name (any name), provider, voice_name, AWS_engine], [guest name, provider, voice_name, AWS_engine]
        case learning_languages.fr_FR:
            pairs = [
                [["Rémi", "AWS", "Remi", "neural"], ["Amanda", "Google", "fr-FR-Wavenet-C", ""]]
            ]
            break;
        case learning_languages.ru_RU:
            pairs = [
                // [["Eduard", "Google", "ru-RU-Wavenet-B", ""], ["Tatyana", "Google", "ru-RU-Wavenet-C", ""]]
                [["Eduard", "Google", "ru-RU-Wavenet-B", ""], ["Tatyana", "OpenAI", "nova", "tts-1"]]
            ]
            break;
        case learning_languages.en_US:
        default:
            pairs = [
                [["Eduard", "Google", "ru-RU-Wavenet-B", ""], ["Tatyana", "OpenAI", "nova", "tts-1"]],
                // [["Bradley", "AWS", "Matthew", "neural"], ["Amanda", "Google", "en-US-Wavenet-F", ""]], // Favorite pair
                // [["Amanda", "Google", "en-US-Neural2-F", ""], ["Richard", "AWS", "Joey", "neural"]],
            ]
            break;
    }
    return pairs;
}

export let speech_audio = new Audio();
let intro_audio = new Audio();
let host_voice;
export let audio_context;
// export let guest_voice;
export let guest_voice = {
    provider: null,
    voice: null,
    engine: null,
}


export async function guest_says(text) {
    log("guest_says" + text)
    // if(app_state.state === app_state.states.podcast_ongoing)

    if (!guest_voice.voice) setVoices();
    await sayText(text, guest_voice.provider, guest_voice.voice, guest_voice.engine);
}

async function sayText(text, provider, voice, engine) {
    log("sayText")
    try {
        if (!host_voice) setVoices();
        if (podcast_or_story_is_pausing()) return;

        let metadata = {
            text: text,
            provider: provider,
            voice: voice,
            language: settings.learning_language.value,
            engine: engine,
            max_response_length: max_ai_response_length,
        };
        const response = await request_and_validate("tts", 'POST', JSON_to_FormData(metadata));
        if (!response) return false;

        const mp3 = new Blob([response.data], { type: 'audio/mp3' });
        app_state.AI_response_audio = URL.createObjectURL(mp3);

        // audio_buffer_url = 'https://eng-ai-public-s3-bucket.s3.us-west-2.amazonaws.com/upbeat_4sec.mp3'; //for debugging
        await playSound(app_state.AI_response_audio);

        log("sayText - Finished")
    }
    catch (e) {
        log(e);
    }
}

export async function playSound(url) {
    // log("playSound");

    if (!url) return;

    if (is_iOS()) turn_on_mic(); //otherwise volume is not applied

    animation(true);
    if (!debug_mode) await start_screen_lock();

    speech_audio = new Audio(); // always new to remove previous audio
    speech_audio.src = url;
    speech_audio.crossOrigin = 'anonymous'; // needed to play files from S3

    let volume = 2.5;
    // if (guest_voice.provider === "OpenAI") { volume = 2.5;}
    log("volume", volume);
    await init_audio(speech_audio, volume);

    await speech_audio.play();
    let duration = speech_audio.duration;
    if(is_iOS()) duration += 0.2;
    await delay(duration);

    await stop_screen_lock();
    await animation(false);

    // log("playSound - finished");
    app_state.audio_suspended = false; // for Safari

    if (is_iOS()) turn_off_mic(); //otherwise volume is not applied
}

export async function playIntro() {
    if (app_state.state === app_state.states.podcast_pausing) return;

    // if(debug_mode) return;
    // if(is_iOS()) return; // Skip for mobile Safari to save time

    // Play in with a new audio element to prevent wierd sound notifications on mobile
    let url = 'https://eng-ai-public-s3-bucket.s3.us-west-2.amazonaws.com/upbeat_4sec.mp3';

    // if (is_iOS()) turn_on_mic(); //otherwise volume is not applied -> causes issues on iOS

    // if (!intro_audio)
    intro_audio = new Audio();

    intro_audio.src = url;
    intro_audio.crossOrigin = 'anonymous';
    intro_audio.volume = 0.7;
    await init_audio(intro_audio, intro_audio.volume); // gain 0 only lowers sound to the minimum

    await intro_audio.play();
    let overlap = 2; // [sec] overlap with speech

    if (settings.app_mode.value !== settings.app_mode.modes.Brush_up.name)
        await delay(intro_audio.duration - overlap);

    // if (is_iOS()) turn_off_mic(); //otherwise volume is not applied
}

/////////////////////  MANAGING THE STATE ////////////////////////

async function init_audio(audio, volume) {
    log("init_audio");
    audio_context = new AudioContext();
    let audio_source = await audio_context.createMediaElementSource(audio);
    await audio_source.connect(audio_context.destination);

    const gainNode = await audio_context.createGain();
    gainNode.gain.value = volume; // change the volume
    await audio_source.connect(gainNode);
    await gainNode.connect(audio_context.destination); // connect the gain node to an output destination
}

async function sound_got_suspended() {
    if (!audio_context) return false;

    let got_suspended = false;
    const was_suspended = app_state.audio_suspended;
    const now_suspended = audio_context.state === 'suspended';

    if (!was_suspended && now_suspended) {
        got_suspended = true; // audio was not suspended, but it is suspended now
    }
    app_state.audio_suspended = now_suspended;
    log("audio_context.state: " + audio_context.state)
    return got_suspended; // audio was suspended before, nothing change -> try to play audio
}

export function setVoices() {
    let pairs = get_voice_pairs();
    let pair = pairs[0];
    // log(pair);
    if (settings.change_voices.value) {
        let index = getRandomNumber(pairs.length);
        pair = pairs[index];
    }
    host_voice = pair[0];
    // guest_voice = pair[1];

    guest_voice.provider = pair[1][1];
    guest_voice.voice = pair[1][2];
    guest_voice.engine = pair[1][3];

    // log("setVoices. pair:" + pair.toString());
}

export function sound_was_started() {
    // log("speech_audio.currentTime: " + speech_audio.currentTime)
    if (speech_audio.currentTime > 0)
        return true;
    else
        return false;
}

export function sound_is_paused() {
    let paused = false;
    if (sound_was_started() && speech_audio.paused && speech_audio.currentTime < speech_audio.duration)
        paused = true;
    return paused;
}

export async function TTS_Pause_Playing() {
    if (speech_audio)
        await speech_audio.pause();
    if (intro_audio)
        await intro_audio.pause();
    await animation(false);
    // audio_allowed = false;
}

export async function TTS_Resume_Playing() {
    log("TTS_Resume_Playing");
    await animation(true);
    // if (!speech_audio === undefined)
    if (speech_audio) {
        await speech_audio.play();
    }
    await animation(false);
    // audio_allowed = true;
}

export async function TTS_Reset() {
    log("TTS_Reset");
    speech_audio.pause();
    speech_audio.currentTime = 0;
    // speech_audio = new Audio();
    await animation(false);
    // log("speech_audio.currentTime: " + speech_audio.currentTime);
}

// function getAWS_Voice(male = false) {
//     let voice;
//     if (male) {
//         let index = getRandomNumber(AWS_male_voices.length);
//         voice = AWS_male_voices[index];
//     }
//     else{
//         let index = getRandomNumber(AWS_female_voices.length);
//         voice = AWS_female_voices[index];
//     }
//     // console.log(phrase)
//     return ["AWS", voice]
// }

// function getGoogle_Voice(male = false) {
//     let voice;
//     if (male) {
//         let index = getRandomNumber(Google_male_voices.length);
//         voice = Google_male_voices[index];
//     }
//     else{
//         let index = getRandomNumber(Google_female_voices.length);
//         voice = Google_female_voices[index];
//     }
//     // console.log(phrase)
//     return ["Google", voice]
// }

// export async function getOpeningPhase(){
//     return "Welcome to our podcast about the " + word_type + " " + addQuotes(app_state.expression) +
//     " with an AI expert " + guest_voice[0]
// }