// Source - ChatGPT
import React from 'react';
import {btn_Play_onClick, setExpression, translateWord} from "../main_page";
import {app_state, settings} from "../sub_pages/settings";
import {log} from "./helpers";

export const menu_autocomplete_id = "menu_autocomplete";
const maxNumber = 10;

let inputValue = "";
let filteredSuggestions = [];
let showSuggestions = false;
// let menuOpen = true;
export let popular_word_dictionary;

export default class Auto_Complete_Input extends React.Component {

    constructor(props) {
        super(props);
        this.state = {"updated": null};
        window.TextField = this;
    }

    componentDidMount() {
    }

    // log("Auto_Complete_Input");
    // log("Auto_Complete_Input app_state.expression: " + app_state.expression);

    // const [inputValue, setInputValue] = useState("");
    // const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    // const [showSuggestions, setShowSuggestions] = useState(false);
    // const [menuOpen, setMenuOpen] = useState(true);

    render() {
        return (
            // style={{width: "80%"}}
            <div id="div_input" >
                <input
                    autoComplete="off"
                    onKeyDown={inp_Phrase_onKeyDown}
                    onFocus={inp_Phrase_onFocus}
                    required
                    id='inp_learning_phrase'
                    defalut="Word to learn"
                    placeholder="word to learn"
                    className="input input_word"
                    type="text"
                    value={inputValue}
                    onChange={inp_Phrase_onChanged}
                    style={{fontSize: "22px", margin: "0"}}
                />
                {renderSuggestions()}
            </div>
        );
    }
}

export function initialize_Autocompletion(pop_words){
    popular_word_dictionary = pop_words;
    // log("popular_word_dictionary", popular_word_dictionary[0])
}

export function setInput_Word(value){
    inputValue = value;
    window.TextField.setState({"updated": null});
}

const inp_Phrase_onKeyDown = async event => {
    if (event.key === 'Enter') {
        event.preventDefault();
        document.getElementById("btn_Start").click();
    }
}

const inp_Phrase_onFocus = async event => {
    // Select all text when the user clicks on the control
    event.currentTarget.setSelectionRange(0, event.currentTarget.value.length)
}

const inp_Phrase_onChanged = async event => {
    setExpression(event.currentTarget.value.trim());

    const value = event.target.value;
    log("event.target.value: " + value);

    let filtered = [];

    if (value.length > 1) {
        filtered = popular_word_dictionary.filter((suggestion) =>
            suggestion.toLowerCase().startsWith(value.toLowerCase())
        );
    }

    log("setInputValue: " + value);
    inputValue = value;
    window.TextField.setState({"updated": null});

    filteredSuggestions = filtered;
    showSuggestions = true;

};

function renderSuggestions() {
    if (showSuggestions && inputValue) {
        if (filteredSuggestions.length > 0) {
            // const control = document.getElementById(menu_autocomplete_id);
            // control.className = "dropdown-menu";
            return (
                <div id={menu_autocomplete_id} className="dropdown-menu"
                     style={{left: '0', width: "calc(100% - 42px)"}}>
                    {/*<div className="dropdown-menu" style={{left: 'calc(50% - 125px)', width:"100%"}}>*/}
                    <ul style={{padding: "0", margin: "0", fontSize: "20px"}}>
                        {filteredSuggestions.map((suggestion, index) => (
                            <li style={{display: getDisplay(index)}} key={suggestion}
                                onClick={() => suggestionSelected(suggestion)}>
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                </div>
            );
        } else {
            return null;
        }
    }
    return null;
};


function suggestionSelected(value) {
    setExpression(value.trim());
    log("suggestionSelected: " + value);

    inputValue = value;
    window.TextField.setState({"updated": null});

    showSuggestions = false;
    if (settings.app_mode.value === settings.app_mode.modes.Dictionary.name)
        btn_Play_onClick();
};

function getDisplay(index) {
    if (index > maxNumber)
        return "none";
    else
        return "block";
}
