import React from 'react';
import {GoogleLogin, googleLogout, useGoogleOneTapLogin} from '@react-oauth/google' // https://github.com/MomenSherif/react-oauth
import JWT_decode from "jwt-decode";
import Cookies from 'universal-cookie';
import {Google_Analytics_Event, log, please_log_in_phrase, showNotification, showSubPage} from "./helpers";
import {debug_mode} from "../app_settings";
import {loadVocabulary} from "../sub_pages/vocabulary";
import {resetStatistics} from "./statistics";
import {loadSettingsFromDB, tryGetSetting} from "../sub_pages/settings";
import {request_and_validate} from "./request";
import {load_control_reset} from "./load_control";
import {reset_Subscription_Data} from "./subscription_info";
const cookies = new Cookies();
export let JWT = null;
export let JWT_decoded; // JWT token content: https://developers.google.com/identity/gsi/web/guides/handle-credential-responses-js-functions
export let user_id_in_DB = null;
const JWT_cookie_name = "GoogleJWT"

//
export const btn_login_main = "btn_login_main"; // links to images for the gallery
export const btn_login_extra = "btn_login_extra"; // links to images for the gallery

export default class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menu_open: false};

        if(this.props.id === btn_login_main) //only the main login button (on the top) should convert to the avatar
            window.GoogleLogin = this;
    }

    render() {

        let google_button = <div/>;

        if (JWT) {
            google_button = <div>
                <img src={JWT_decoded["picture"]} alt="user image"
                     style={{maxWidth: "32px", maxHeight: "32px", borderRadius: "16px"}}
                     className="icon_avatar"/>
            </div>
        }

        return (

            <div>
                {JWT ?
                    <div className="div_horizontal">
                        {google_button}
                        <button className="button" onClick={logOut}>Log out</button>
                    </div>
                    :
                    <GoogleLogin
                        onSuccess={onSuccess}
                        onError={onFailure}
                        auto_select  //no need to sign in second time - https://developers.google.com/identity/gsi/web/guides/automatic-sign-in-sign-out
                        // text="signin"
                        // useOneTap  //shows an annoying pop-up window with Google accounts automatically
                    />
                }
            </div>
        );
    }
}

const onSuccess = async (credentialResponse) => {
    log("Google login. onSuccess");
    Google_Analytics_Event("Google Login", "User logged in via Google");

    setJWT_value(credentialResponse["credential"]);
    save_Token_to_Cookies(JWT);

    await loadSettingsFromDB();
    await loadVocabulary();
    await showSubPage("div_main_content");
}

const onFailure = () => {
    log('Login failed');
    setJWT_value(null);
    alert('Login failed');
}

function save_Token_to_Cookies(JWT){
    // path = / - cookie will be accessible on all pages
    // httpOnly = true - only the server can access the cookie, not shown in Chrome -> Inspect -> Application (put false for debugging)
    // An attacker can launch a script to get the token via code injection
    cookies.set(JWT_cookie_name, JWT, { path: '/' , httpOnly: false});
    log("saved JWT");
}

export function logOut(){
    log("logOut")
    googleLogout();
    setJWT_value(null);
    save_Token_to_Cookies(null);
    resetStatistics();
    load_control_reset();
    reset_Subscription_Data();
}

export function set_user_id(value){
    user_id_in_DB  = value;
}

export function setJWT_value(value){
    // log("setJWT_value: " + value)
    JWT  = value;
    if(JWT)
        JWT_decoded = JWT_decode(JWT);
    else
        JWT_decoded = null;

    window.Menu_User.setState({update_UI: true});
    window.Menu_Mode.setState({update_UI: true});
    window.GoogleLogin.setState({update_UI: true});
}

export async function load_JWT() {
    log("load JWT");
    try {
        setJWT_value(cookies.get(JWT_cookie_name));
    }
    catch (ex){
        log("load JWT error: " + ex);
    }
}

export function check_Auth_Locally(){
    if (!JWT){
        // console.error("No authorization");
        showNotification(please_log_in_phrase);
        return false;
    }
    return true;
}

export async function check_Auth() {
    log("check_Auth");
    if (!JWT) return false;

    const response = await request_and_validate("user", "OPTIONS");
    if (!response) {
        log("!response");
        setJWT_value(null);
        return false;
    }

    // Check whether the response is valid by getting the email
    let user_email = await tryGetSetting(response,"email",true,0);
    // log(response);
    if(!user_email) {
        setJWT_value(null);
        return false;
    }

    return true;
}
