import React from "react";

export default class Contact_Info extends React.Component {

    render() {
        return (
            <div style={{color:"gray", marginTop: "1em"}}>
                <b>Contact Information</b><br/><br/>
                <a href="mailto: english.ai.mailbox@gmail.com">english.ai.mailbox@gmail.com</a><br/>
                Address: Georgia, Ambrolauri district, administrative unit Khvanchkara, block N001, plot N047
                <br/><br/>
            </div>
        )
    }
}
