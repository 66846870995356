import {
    guest_voice,
    playIntro, playSound, setVoices,
    sound_is_paused,
    TTS_Reset
} from "./tts";
import {
    getExclamation,
    getFarewell,
    getQuestion,
    getQuestionNumber,
    getRecommendation
} from "../resources/phrases";
import {set_Text_to_Translate} from "./translation_block";
import {setExpression, update_Controls, word_type} from "../main_page";
import {ask_AI, ask_AI_and_pronounce, clean_response, open_ai_chat_model} from "./APIs";
import {add_tts_parameters_to_json, addQuotes, JSON_to_FormData, log, request_types, setDisplayValue, toss_a_coin} from "./helpers";
import {app_state, settings} from "../sub_pages/settings";
import {setInput_Word} from "./autocmoplete";
import {request_and_validate} from "./request";

let question;
let phrase_examples = ""; // sentences with the learning phrase
let exclamation;
// export let app_state.podcast_question_number = 0; // last question number for the pause function
export let podcast_started = false; // flag for the pause function
let number_of_questions = 0; // number of questions in the podcast
// let response;

export async function runPodcast() {
    log("runPodcast");

    if (app_state.state === app_state.states.podcast_pausing) return;
    podcast_started = true;

    if (app_state.podcast_question_number === 0 &&
        app_state.question_state === app_state.question_states.not_started) {
        await playIntro();
        app_state.question_state = app_state.question_states.intro_finished;
    }

    if (app_state.state === app_state.states.podcast_pausing ||
        app_state.state === app_state.states.podcast_paused) return;
    await podcast();

    if (!sound_is_paused() && app_state.podcast_question_number === number_of_questions) {
        log("podcast has been finished")
        app_state.state = app_state.states.none;
        update_Controls();
        reset_podcast();
    }
    log("runPodcast stopped");
}

export async function podcast() {
    log("podcast. question_number: " + app_state.podcast_question_number);

    number_of_questions = getQuestionNumber(word_type, addQuotes(app_state.expression));
    log(number_of_questions)

    for (let i = app_state.podcast_question_number; i < number_of_questions; i++) {
        log("question_number : " + app_state.podcast_question_number);
        if (podcast_or_story_is_pausing()) return;

        question = await getQuestion(i);
        // log("question",question)
        await ask_AI_and_pronounce(question[0])
        app_state.podcast_question_number++;

        if (podcast_or_story_is_pausing()) return;
    }

    log("podcast - ended");
}

export function podcast_or_story_are_ongoing(){
    if(app_state.state === app_state.states.podcast_ongoing ||
        app_state.state === app_state.states.story_ongoing)
        return true;
    else
        return false;
}

export function podcast_or_story_is_pausing(){
    if(app_state.state === app_state.states.podcast_pausing ||
        app_state.state === app_state.states.story_pausing )
        return true;
    else
        return false;
}

export function podcast_is_paused_or_pausing(){
    if(app_state.state === app_state.states.podcast_pausing ||
        app_state.state === app_state.states.podcast_paused )
        return true;
    else
        return false;
}

export async function initialize_podcast() {

    //Load last app_state.expression
    if (app_state.expression) {
        await setExpression(app_state.expression, false);
        setInput_Word(app_state.expression);
    }

    // Remove default submit to prevent the page reload when start is clicked
    const form = document.getElementById('div_podcast_controls');
    form.addEventListener('submit', function (e) {
        e.preventDefault();
    });
}

export function next_question(){
    log("next_question")
    app_state.question_state = app_state.question_states.not_started;
    app_state.podcast_question_number++;
    app_state.AI_response = null;
    app_state.AI_response_audio = null;
}

export function reset_podcast() {
    log("reset_podcast");
    podcast_started = false;
    app_state.podcast_question_number = 0;
    number_of_questions = 0;
    app_state.question_state = app_state.question_states.not_started;
    app_state.AI_response = null;
    // app_state.AI_response_audio = null;
    // app_state.audio_suspended = false;
}

////////////////////// DETECTING PHRASE FINISHING /////////////////////////////////

export function phrase_was_finished(){
    let finished = false;
    if(app_state.state !== app_state.states.podcast_pausing &&
        app_state.state !== app_state.states.podcast_paused &&
        !sound_is_paused())
        finished = true;

    log("phrase_was_finished: " + finished);
    return finished;
}

export async function guest_finished() {
    log("guest_finished")
    next_question();
    await TTS_Reset();
}

export async function host_finished() {
    // log("coming_from_my_place: " + coming_from_my_place);
    log("host_finished");
    app_state.question_state = app_state.question_states.host_finished;
    await TTS_Reset();
}

// async function exclame(){
//     exclamation = getExclamation(50)
//     if (exclamation !== "") {
//         await host_says(exclamation)
//     }
// }

// export async function farewell() {
//     await host_says("Thanks for being with us")
//     let phrase_to_say = getFarewell();
//     await guest_says(phrase_to_say);
//     phrase_to_say = getRecommendation();
//
//     const phrase_to_show = phrase_to_say + phrase_examples;
//     set_Text_to_Translate(phrase_to_show)
//     await guest_says(phrase_to_say);
// }

// export async function introduction() {
//     await host_says(await getOpeningPhase())
// }
