import React from "react";

export function Spinner() {
    return (
        <div className="spinner">
            <div className="spinner_arc spinner_arc_1"></div>
            <div className="spinner_arc spinner_arc_2"></div>
            <div className="spinner_arc spinner_arc_3"></div>
            <div className="spinner_arc"></div>
        </div>
    )
}

export function SpinnerBig() {

    let picture_size = 10; //talking head size in rem
    let size_int = picture_size + 1; //spinner size
    let size = size_int + "rem"
    let shift_top = "-" + size_int/2 + "rem"
    let shift_left = "-" + (size_int - (size_int - picture_size)/2) + "rem"
    let arc_style = {width:size,height:size, marginLeft: shift_left, marginTop: shift_top}

    return (
        <div className="spinner" style={{width: size, height: size, position:"absolute"}}>
            <div className="spinner_arc spinner_arc_1" style={arc_style}></div>
            <div className="spinner_arc spinner_arc_2" style={arc_style}></div>
            <div className="spinner_arc spinner_arc_3" style={arc_style}></div>
            <div className="spinner_arc" style={arc_style}></div>
        </div>
    )
}