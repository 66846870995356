import React from 'react';
import back from "../../images/back.svg";
import { request_and_validate } from "../elements/request";
import { debug_mode } from "../app_settings";
import { check_Auth_Locally, JWT, set_user_id } from "../elements/google_sso_login";
import {
    getLangCodeByName,
    getLangNameByCode, Google_Analytics_Event, JSON_to_FormData,
    log,
    showNotification,
    showSubPage
} from "../elements/helpers";
import { Spinner } from "../elements/spinner";
import Payment_Page_Button from "../payment/btn_pay_page";
import Log_in_label from "../elements/log_in_label";
import uuid from "react-uuid";
import { reset_phrases } from "../resources/phrases";
import { translation_languages } from "../resources/translation_languages";
import { show_statistics } from "../elements/statistics";
import { load_control, makes_sense_to_load_again } from "../elements/load_control";
import Subscription_Info, { get_subscription } from "../elements/subscription_info";
import { word_types } from "../main_page";

// App settings
let loading = false;
let initializing = false;
const brush_up_mode_name = 'brush_up_mode';
// Current content options with default values, can be updated to add new controls
const content_default = { "Definition": false, "Examples": true, "Funny story": false, "Typical context": false, "Etymology": false, "Questions": false };
export let learning_languages = {
    en_US: "en-US",
    ru_RU: "ru-RU",
    fr_FR: "fr-FR"
}
export const max_ai_response_length = 500; // [symbols]

// Structure: <setting_name>:{name:<>, value:<>, etc.}
// name and value are mandatory
// name = control id, param name in JSON
export let settings = {
    app_mode: {
        value: brush_up_mode_name, name: "app_mode", //value - default value
        modes: {
            // Default  : {name: 'Default', control_name: 'cbx_default_mode', title:"Classic"},
            Brush_up: { name: brush_up_mode_name, control_name: 'cbx_brush_up_mode', title: "Learning" },
            Kids: { name: 'kids_mode', control_name: 'cbx_kid_mode', title: "Kids" },
            Dictionary: { name: 'vocabulary_mode', control_name: 'cbx_dictionary_mode', title: "Dictionary" },
        }
    },

    // content items are initialized with default values, other values from the DB should be ignored for the sake of compatibility
    content: { value: Object.entries(content_default), name: "content", control_prefix: "cbx_question_", visible: false },
    show_images: { value: true, name: "show_images", title: "Show images", description: "Show the control button at the bottom", visible: true },
    native_language: { value: "de", name: "native_language_code", visible: true },
    learning_language: { value: "en-US", name: "learning_language_code", visible: false }, //Options: "en-US", "fr-FR"
    change_voices: { value: false, name: "change_voices", title: "Change voices", description: null, visible: false },
    auto_translation: { value: true, name: "auto_translation", title: "Automatic translation", description: "Translate all texts automatically", visible: false },
    // TO DO the control is not finished and hidden from the user
    // show_bottom_button:{value:false, name:"show_bottom_button", title:"Bottom button", description:"Show the control button at the bottom" },
    last_popular_word: { value: "", name: "last_popular_word" },
    radial_button: { value: true, name: "radial_button", title: "Show flower buttons", description: null, visible: true },
    show_word_frequency: { value: true, name: "show_word_frequency", title: "Word's frequency", description: "Show word's position in the frequency list", visible: true },
    auto_word_example: { value: false, name: "auto_word_example", title: "Give an example", description: "Automatically provide an example when the button \"Next word\" is clicked", visible: false },
    play_background_sound: { value: false, name: "play_background_sound", title: "Play background sound", description: null, visible: true },
}

export let app_state = {
    // Podcast
    // podcast_is_ongoing: false, // true = podcast is ongoing
    // stopping_podcast: false, // true = podcast should be stopped

    expression: null,    // Current word/expression we are learning without quotes
    translation: null,    // Current word's translation
    word_type: word_types.word,      // learning word / expression, default - word
    AI_response: null, // AI response to the question
    AI_response_audio: null, // AI response to the question, a link to the audio file
    audio_suspended: false, // playing audio was suspended by Safari
    audio_recording: false, // audio is being recorded
    AI_response_was_loaded: false, // for Safari
    last_user_request: null, // last user request to AI
    question_state: "not_started",
    // flow not_started -> intro_finished -> host_finished -> (once guest finishes) not_started
    question_states: {
        not_started: "not_started",
        intro_finished: "intro_finished",
        host_finished: "host_finished",
        guest_started: "guest_started",
    },
    // host_finished: false, // host finished their phrase or it's not needed

    // MODE
    old_word_mode: false, // old - a practiced earlier word
    last_word_from_vocabulary: null, // original word from the vocabulary

    state: "stopped", // state of playing sound (story / podcast)

    // flow example: ongoing -> pausing -> paused / stopped
    // s - story
    // p - podcast
    states: {
        none: "stopped", // stopped
        loading_data: "loading_data",
        podcast_ongoing: "p_ongoing",
        podcast_pausing: "p_pausing",
        podcast_paused: "p_paused",
        story_ongoing: "s_ongoing",
        story_pausing: "s_pausing",
        story_paused: "s_paused",
        reading_text: "reading_text", //reading the text again
    },
    podcast_question_number: 0, // last question number for the pause function
}

// How to update settings
// 1. Add a new item in the settings structure
// 2. Add JSX to render -> return
// 3. Update "initialize" method
// 4. Add an event handler to onSelectChange
// 5. Add setting load logic to applySettingValue

export default class Settings extends React.Component {

    constructor(props) {
        super(props);
        this.state = { loading: false };
        window.Settings = this;
    }

    async componentDidMount() {
        await initialize_Settings();
    }

    render() {

        const { loading } = this.state;
        // const subscription = get_subscription_info_JSX();

        let display_value;
        if (!loading && JWT)
            display_value = "block";
        else
            display_value = "none";


        // let show_ai_buttons = getSettingJSX(settings.show_ai_buttons.title, settings.show_ai_buttons.name, settings.show_ai_buttons.description);
        let change_voices = getSettingJSX(settings.change_voices.title, settings.change_voices.name,
            settings.change_voices.description, "0px", settings.change_voices.visible);
        let show_images = getSettingJSX(settings.show_images.title, settings.show_images.name, settings.show_images.description);
        let app_mode = getAppModeJSX();
        let content = getContentJSX();
        let native_language = getLanguageJSX();
        // let radial_button = getSettingJSX(settings.radial_button.title, settings.radial_button.name,
        //     settings.radial_button.description);
        let show_word_frequency = getSettingJSX(settings.show_word_frequency.title, settings.show_word_frequency.name,
            settings.show_word_frequency.description);
        let auto_translation = getSettingJSX(settings.auto_translation.title, settings.auto_translation.name,
            settings.auto_translation.description);
        let auto_word_example = getSettingJSX(settings.auto_word_example.title, settings.auto_word_example.name,
            settings.auto_word_example.description, "0px", settings.auto_word_example.visible);
        // let show_bottom_button = getSettingJSX(settings.show_bottom_button.title, settings.show_bottom_button.name,
        //     settings.show_bottom_button.description);
        // let play_background_sound = getSettingJSX(settings.play_background_sound.title, settings.play_background_sound.name,
        //     settings.play_background_sound.description);


        return (
            <div id="div_settings" className="div_sub_page">

                <div className="div_settings_header" style={{ marginBottom: "20px" }}>
                    <img className="button button_back" src={back} onClick={btnBack_onClick} />
                    <label className="settings_header">Settings</label>
                </div>

                {JWT && loading ? <div className="div_vertical"><Spinner /></div> : <div />}
                <Log_in_label />

                <div>
                    <div style={{ display: display_value }}>
                        <Subscription_Info />
                        {/*{subscription}*/}
                        <Payment_Page_Button />
                        {/*<SelectLanguage/>*/}
                        {native_language}
                        {app_mode}
                        {content}
                        {/*{show_ai_buttons}*/}
                        {/*{radial_button}*/}
                        {show_word_frequency}
                        {auto_translation}
                        {auto_word_example}
                        {change_voices}
                        {show_images}
                        {/*{show_bottom_button}*/}
                        {/*{play_background_sound}*/}
                    </div>
                </div>
                <div style={{ height: "3em" }} />
            </div>
        )
    }
}

async function initialize_Settings() {
    // log("initialize_Settings");
    if (initializing) return;
    else initializing = true;

    let settings_obj = Object.entries(settings);
    for (let i = 0; i < settings_obj.length; i++) {
        await initialize_Setting(settings_obj[i][1]["name"]);
    }

    initializing = false;
}

////////////////////// EVENT HANDLERS /////////////////////////////////

export const btnOpenSettings_onClick = async event => {
    log("btnOpenSettings_onClick");
    Google_Analytics_Event("Main", "Open Settings onClick");

    await window.Settings.setState({ loading: true });
    // window.Settings.forceUpdate(); // rerender the component
    await showSubPage("div_settings");
    await loadSettingsFromDB();
    await window.Settings.setState({ loading: false });
    await get_subscription();
}

const btnBack_onClick = async event => {
    await showSubPage("div_main_content");
    updateControlStatus();
}

export const onSettingClick = async event => {
    log("onSelectChange");
    let control = document.getElementById(event.currentTarget.id);

    log("event.currentTarget.id: " + event.currentTarget);
    switch (event.currentTarget.id) {
        // case settings.show_bottom_button.name:
        //     settings.show_bottom_button.value = control.checked;
        //     break;

        // case settings.show_ai_buttons.name:
        //     settings.show_ai_buttons.value = control.checked;
        //     break;

        case settings.change_voices.name:
            settings.change_voices.value = control.checked;
            break;

        case settings.auto_translation.name:
            settings.auto_translation.value = control.checked;
            break;

        // case settings.play_background_sound.name:
        //     settings.play_background_sound.value = control.checked;
        //     break;

        case settings.show_images.name:
            settings.show_images.value = control.checked;
            break;

        case settings.show_word_frequency.name:
            settings.show_word_frequency.value = control.checked;
            break;

        case settings.auto_translation.name:
            settings.auto_translation.value = control.checked;
            break;

        case settings.auto_word_example.name:
            settings.auto_word_example.value = control.checked;
            break;

        // case settings.app_mode.modes.Default.control_name:
        //     await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Default.name);
        //     // if (document.getElementById(settings.app_mode.modes.Kids.control_name).checked)
        //     //     await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Kids.name);
        //     // else
        //     //     await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Default.name);
        //     break;

        case settings.app_mode.modes.Brush_up.control_name:
            if (document.getElementById(settings.app_mode.modes.Brush_up.control_name).checked)
                await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Brush_up.name);
            else
                await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Dictionary.name);
            // await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Default.name);
            break;

        case settings.app_mode.modes.Dictionary.control_name:
            if (document.getElementById(settings.app_mode.modes.Dictionary.control_name).checked)
                await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Dictionary.name);
            else
                await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Brush_up.name);
            // await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Default.name);
            break;

        case settings.app_mode.modes.Kids.control_name:
            if (document.getElementById(settings.app_mode.modes.Kids.control_name).checked)
                await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Kids.name);
            else
                await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Brush_up.name);
            // await applySettingValue(settings.app_mode.name, settings.app_mode.modes.Default.name);
            break;

        case settings.native_language.name:
            const language_name = control.options[control.selectedIndex].value;
            settings.native_language.value = await getLangCodeByName(translation_languages, language_name);
            // log("onSelectChange. language: " + settings.native_language.value);

            saveSettingsToDB();
            log("Native language saved : " + settings.native_language.value);
            break;

        // case settings.radial_button.name:
        //     settings.radial_button.value = control.checked;
        //     updateControlStatus();
        //     break;
    }

    // Check content controls validity
    if (event.currentTarget && event.currentTarget.id.includes(settings.content.control_prefix)) {
        const control_name = event.currentTarget.id;
        const question = control_name.toString().substring(settings.content.control_prefix.length);
        // const control = document.getElementById(control_name);
        const value = control.checked;

        if (getContent_Item_Amount() === 1 && value === false) {
            showNotification("At least 1 content item should be selected", "error");
            control.checked = true;
            return;
        }

        const question_index = getQuestionIndex(question);
        if (question_index >= 0)
            settings.content.value[question_index][1] = value;

        log(settings.content.value[question_index][0] + "= " + settings.content.value[question_index][1]);
    }

    saveSettingsToDB();
}

////////////////////// SAVE/LOAD SETTINGS /////////////////////////////////

export async function saveSettingsToDB(show_notification = true) {
    log("saveSettingsToDB");
    if (!check_Auth_Locally()) return;

    let json_params = {};

    let settings_obj = Object.entries(settings);
    for (let i = 0; i < settings_obj.length; i++) {
        json_params[settings_obj[i][1]["name"]] = settings_obj[i][1]["value"];
    }

    // log("saveSettingsToDB: " + JSON.stringify(json_params));
    const response = request_and_validate("settings", "POST", JSON_to_FormData(json_params));
    // const response = request_and_validate("settings", "POST", json_params);
    if (!response) return false;

    // if(await check_response_errors(response)) return false;

    // if(await checkResponse(response))
    if (show_notification) showNotification("Settings have been saved");
}

export async function loadSettingsFromDB() {
    if (!makes_sense_to_load_again(load_control.settings.name)) return true;
    log("loadSettingsFromDB");
    if (!check_Auth_Locally()) return false;
    // if(!await get_subscription()) return false; // Subscription is checked every request anyways

    // log("JWT: " + JWT)
    const response = await request_and_validate("settings", "OPTIONS");
    if (!response) return false;
    // log(response.data)
    load_control.settings.last_load_time = Date.now();

    let value;
    let setting_name;

    let settings_obj = Object.entries(settings);
    for (let i = 0; i < settings_obj.length; i++) {
        setting_name = settings_obj[i][1]["name"];
        value = await tryGetSetting(response, setting_name);
        if (value === 'Default') value = settings.app_mode.modes.Brush_up.name; // backward compatibility: support removed "Default" value
        await applySettingValue(setting_name, value);
        // log("applySettingValue: " + setting_name + " = " + value)
    }
    log("settings.app_mode.value: " + settings.app_mode.value);

    let user_id = await tryGetSetting(response, "user");
    if (user_id) set_user_id(user_id);

    updateControlStatus();
    show_statistics();
    // get_subscription();
}

async function updateControlStatus() {
    if (loading) return;
    else loading = true;

    // log("updateControlStatus");
    await window.Main_page.setState({ loading: false }); //re-render the page
    await window.Pause_Button.setState({ loading: false }); //re-render the page

    // Links
    if (window.links_images) window.links_images.setState({ "update": true });
    if (window.ai_buttons) window.ai_buttons.setState({ "update": true });
    if (window.links_practice) window.links_practice.setState({ "update": true });
    // if(window.links_dictionaries) window.links_dictionaries.setState({"update":true});

    // Radial buttons
    if (window.Radial_btn_links_images) window.Radial_btn_links_images.setState({ "update": true });
    if (window.Radial_btn_ai_buttons) window.Radial_btn_ai_buttons.setState({ "update": true });
    if (window.Radial_btn_links_practice) window.Radial_btn_links_practice.setState({ "update": true });
    // if(window.Radial_btn_links_dictionaries) window.Radial_btn_links_dictionaries.setState({"update":true});

    loading = false;
}

export async function tryGetSetting(response, setting_name, serialized_object = false, json_index = 0) {
    // log("tryGetSetting");

    // json_obj_index is needed when data consists of multiple JSONs
    // log( response);
    if (!response) return null;
    let setting;

    try {
        if (serialized_object)
            setting = response.data[json_index]["fields"][setting_name];
        else
            setting = response.data[setting_name];

        // log(setting_name + ": " + setting);
    } catch (ex) {
        console.error(ex);
        return null;
    }

    if (setting === "None" || setting === "" || setting === undefined)
        return null;

    return setting;
}

////////////////////// OTHER /////////////////////////////////

export function getSettingJSX(title, id, description = null, margin_left = "0px", visible = true) {

    let margin_top = "40px";
    if (margin_left === "20px")
        margin_top = "20px";

    //<div style={{textAlign: "left", marginTop: margin_top}} key={uuid()}>
    // Settings don't work with key={uuid()}

    let in_display = "block";
    if (!visible)
        in_display = "none"

    // Hide the setting
    return <div style={{ textAlign: "left", marginTop: margin_top, display: in_display }} key={id}>
        <div className="div_horizontal_toggle">
            <div className="div_vertical_left" >
                <label className="label" style={{ fontWeight: "normal", marginLeft: margin_left }}>{title}</label>
                <label className="label_description" style={{ marginLeft: margin_left }}>{description}</label>
            </div>
            <label className="switch">
                <input id={id} type="checkbox" />
                <span className="toggle"></span>
            </label>
        </div>
    </div>
}

function getAppModeJSX() {
    const margin_left = "20px";

    // let classic_mode = getSettingJSX(settings.app_mode.modes.Default.title  + " (C)", settings.app_mode.modes.Default.control_name,
    //     "", margin_left);

    let kid_mode = getSettingJSX(settings.app_mode.modes.Kids.title, settings.app_mode.modes.Kids.control_name,
        "Show only images", margin_left);

    let dictionary_mode = getSettingJSX(settings.app_mode.modes.Dictionary.title, settings.app_mode.modes.Dictionary.control_name,
        "Show only translation, images and links to the definition", margin_left);

    let brush_up_mode = getSettingJSX(settings.app_mode.modes.Brush_up.title, settings.app_mode.modes.Brush_up.control_name,
        "Learn new and repeat previously studied words", margin_left);

    let JSX =
        <div style={{ textAlign: "left", marginTop: "40px" }}>

            <label className="label">Mode</label>
            {/*{classic_mode}*/}
            {brush_up_mode}
            {dictionary_mode}
            {kid_mode}
        </div>

    return JSX;
}

function getContentJSX() {
    const JSX =
        // <div style={{textAlign: "left", marginTop: "40px"}}>
        // make the setting invisible
        <div style={{ display: "none", textAlign: "left", marginTop: "40px" }}>

            <label key={uuid()} className="label">Content</label>

            {settings.content.value.map((question, index) => (
                getSettingJSX(question[0], settings.content.control_prefix + question[0], null, "20px")
            ))}
        </div>
    return JSX;
}

function getLanguageJSX() {
    // log("getLanguageJSX: " + settings.native_language.value);

    const JSX =
        <div style={{ textAlign: "left", marginTop: "40px" }}>
            <div className="div_horizontal_toggle">
                <label className="label">Native language</label>
                <select id={settings.native_language.name} className="select"></select>
            </div>
        </div>
    return JSX;
}

export async function initialize_Setting(control_id, total_initialization = true) {
    // log("initialize_Setting: " + control_id);
    // log("Settings.initialize. Total_initialization:" + total_initialization);
    let control;

    switch (control_id) {
        // case settings.show_bottom_button.name:
        // case settings.show_ai_buttons.name:
        case settings.change_voices.name:
        case settings.show_images.name:
        case settings.show_word_frequency.name:
        case settings.auto_translation.name:
        case settings.auto_word_example.name:
            // case settings.play_background_sound.name:
            simple_initialization(control_id);
            break;

        case settings.app_mode.name:
            let app_modes_obj = Object.entries(settings.app_mode.modes); // JSON to array
            for (let i = 0; i < app_modes_obj.length; i++) {
                // log("settings.app_mode.modes: " + app_modes_obj[i][1]["name"]);
                control = document.getElementById(app_modes_obj[i][1]["control_name"]);
                control.checked = (settings.app_mode.value === app_modes_obj[i][1]["name"]);
                // log("control.checked: " + control.checked);
                if (total_initialization)
                    control.addEventListener('change', onSettingClick);
            }
            break;

        case settings.content.name:
            for (let i = 0; i < settings.content.value.length; i++) {
                let control = document.getElementById(settings.content.control_prefix + settings.content.value[i][0]);
                // log("settings.content.name: " + control)
                control.checked = settings.content.value[i][1];
                if (total_initialization) control.addEventListener('change', onSettingClick);
            }
            reset_phrases();
            break;

        case settings.native_language.name:
            // log("settings.native_language.value: " + settings.native_language.value)
            control = document.getElementById(settings.native_language.name);

            if (total_initialization) {
                // Fill the control with available languages
                if (control && control.options && control.options.length !== 0)
                    return;

                control.addEventListener('change', onSettingClick, false);

                // let languages;
                // Load languages from Google Translate API
                // languages = await requestLanguages();
                // languages = translation_languages;

                // Add language options
                translation_languages.forEach(language => {
                    let option = document.createElement("option");
                    // log(option);
                    option.text = language["name"];
                    control.add(option);
                });
            }

            // Set current language

            let language_name = await getLangNameByCode(translation_languages, settings.native_language.value);
            // log("initialization. native_language. language_name: " + language_name)
            for (let i = 0; i < control.options.length; ++i) {
                if (control.options[i].value === language_name) {
                    control.selectedIndex = i;
                }
            }
            break;

        // case settings.radial_button.name:
        //     simple_initialization(control_id);
        //     break;
    }

}

function simple_initialization(control_id) {
    let control = document.getElementById(control_id);
    if (!control) return;
    control.addEventListener('change', onSettingClick);
    control.checked = settings[control_id].value;
}

async function applySettingValue(control_id, value) {
    // if (debug_mode) console.log("applySettingValue");
    // if(debug_mode)console.log("control_id: " + control_id);

    if (!control_id || value === null) {
        console.log("Null value was provided");
        console.log("control_id: " + control_id + " value: " + value);
        return;
    }

    let control = document.getElementById(control_id);
    // if(!control &&
    //     control_id !== settings.app_mode.name &&
    //     control_id !== settings.content.name ){ // Settings and Content modes have a few controls
    //     log("control not found: " + control_id);
    //     return;
    // }

    switch (control_id) {
        // case settings.show_bottom_button.name:
        //     control.checked = settings.show_bottom_button.value = value;
        //     break;

        // case settings.play_background_sound.name:
        //     control.checked = settings.play_background_sound.value = value;
        //     break;

        case settings.change_voices.name:
            control.checked = settings.change_voices.value = value;
            break;

        case settings.show_images.name:
            control.checked = settings.show_images.value = value;
            break;

        case settings.show_word_frequency.name:
            control.checked = settings.show_word_frequency.value = value;
            break;

        case settings.auto_translation.name:
            control.checked = settings.auto_translation.value = value;
            break;

        case settings.auto_word_example.name:
            control.checked = settings.auto_word_example.value = value;
            break;

        case settings.app_mode.name:
            settings.app_mode.value = value;
            await initialize_Setting(settings.app_mode.name, false)
            window.Menu_Mode.setState({ "update_ui": null });
            updateControlStatus();
            break;

        case settings.content.name:
            let content_from_DB = value;

            // if(debug_mode) console.log("content_from_DB: " + content_from_DB);

            //Take only settings existing in the default list
            //If the question name from the DB matches the name in the default list - take the value (true / false)
            for (let i = 0; i < settings.content.value.length; i++) {
                for (let j = 0; j < settings.content.value.length; j++) {
                    if (settings.content.value[j][0] === content_from_DB[i][0]) {
                        // content name exists in the default list
                        settings.content.value[j][1] = content_from_DB[i][1];
                    }
                }
            }

            // if(debug_mode) console.log("settings.content.name: " + JSON.stringify(settings.content.value));
            // if(debug_mode) console.log("settings.content.name: " + settings.content.value);

            if (getContent_Item_Amount() === 0 && settings.content.value.length > 0)
                settings.content.value[0][1] = true; //at least 1 content item should be selected

            // if(debug_mode) console.log("question_list: " + question_list);
            await initialize_Setting(settings.content.name, false);
            break;

        case settings.native_language.name:
            settings.native_language.value = value;
            await initialize_Setting(settings.native_language.name, false);
            break;

        case settings.last_popular_word.name:
            settings.last_popular_word.value = value;
            break;

        // case settings.radial_button.name:
        //     control.checked = settings.radial_button.value = value;
        //     updateControlStatus();
        //     break;
    }
}

function getContent_Item_Amount() {
    let amount = 0;
    for (let i = 0; i < settings.content.value.length; i++) {
        if (settings.content.value[i][1])
            amount++;
    }
    return amount;
}

export function getQuestionIndex(question) {
    for (let i = 0; i < settings.content.value.length; i++) {
        if (question === settings.content.value[i][0])
            return i;
    }
    return -1;
}

