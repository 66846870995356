import React from 'react';
import {server_URL} from "../app_settings";
import {is_iOS} from "./helpers";

export default class Test_Env_Warning extends React.Component {
    constructor(props) {
        super(props);

        this.state = {"updated": false};
        window.Test_Env_Warning = this;
    }

    render() {

//                { is_iOS() ?
//                    <div className="warning div_vertical">
//                        <label>iPhones are not supported yet, try Android or PC</label>
//                    </div>
//                    :
//                    <div/>
//                }
        return (
            <>
                { isTest_Environment() ?
                    <div className="warning">
                        <label>This is a TEST version. Please visit&nbsp;</label>
                        <a href="https://www.english-ai.org/">english-ai.org</a>
                    </div>
                    :
                    <div/>
                }
            </>
        )
    }
}

function isTest_Environment(){
    // if (server_URL.includes("server.english-ai.org"))
    if(process.env.REACT_APP_ENV_NAME === "Prod")
        return  false;
    else
        return true;
}
