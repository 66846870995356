import React from "react";
import '../../scss/user_menu.scss';
import logout from "../../images/log-out_512.png";
import {JWT, JWT_decoded, logOut} from "../elements/google_sso_login";
import {Menu_Item} from "./menu_mode";

export let div_menu_user_id = "div_menu_user";
export let btn_menu_user_id = "btn_menu_user";

export default class Menu_user extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menu_open: false};
        window.Menu_User = this;
    }

    render() {
        return (

            <div>
                {JWT ?
                    <div className='menu-container'>
                        <div id = {btn_menu_user_id} className='menu-trigger' onClick={btnMenu_onClick}>
                            <img src={JWT_decoded["picture"]}></img>
                        </div>

                        <div id={div_menu_user_id} className={`dropdown-menu ${window.Menu_User.state.menu_open ? 'active' : 'inactive'}`}>
                            <img src={JWT_decoded["picture"]} style={{height: "96px", borderRadius: "50%"}}></img>
                            <h3>{JWT_decoded["name"]}</h3>
                            <ul>
                                <Menu_Item img={logout} text={"Logout"} onClick={onClick}/>
                                {/*<DropdownItem img={close} text={"Close"}/>*/}
                            </ul>
                        </div>
                    </div>
                    :
                    <div>
                    </div>
                }
            </div>
        );
    }
}

const btnMenu_onClick = event => {
    window.Menu_User.setState({menu_open: !window.Menu_User.state.menu_open})
}

const onClick = event => {
    switch (event.currentTarget.id) {
        case "Logout":
            logOut();
            window.Menu_User.setState({menu_open: !window.Menu_User.state.menu_open});
            break;
    }
}

// function Menu_Item(props){
//
//     return(
//         <li className = 'dropdownItem' id={props.text} onClick={props.onClick} style={{listStyle:"none"}}>
//             <img src={props.img}></img>
//             <a style={{textDecoration:"none"}}> {props.text} </a>
//         </li>
//     );
// }


