import React from "react";
import {addWord, removeWord, wordExist} from "./vocabulary";
import back from "../../images/back.svg";
import {debug_mode} from "../app_settings";
import {sendRequest} from "../elements/request";
import {Spinner} from "../elements/spinner";
import {log, showSubPage} from "../elements/helpers";
import {loadSettingsFromDB, saveSettingsToDB, settings} from "./settings";
import {popular_word_dictionary} from "../elements/autocmoplete";

const words_per_page = 10;
let full_word_list = [];
let saved_word_index = 0;
let initialized = false;
// export let last_popular_word;

export default class PopularWords extends React.Component {

    constructor(props) {
        super(props);

        // const remainder = popular_word_dictionary.length % words_per_page;
        // full_word_list = popular_word_dictionary.slice(0, popular_word_dictionary.length - remainder);

        window.PopularWords = this;
        this.state = {"cur_word_index": saved_word_index};
    }

    componentDidMount() {
        // console.log("componentDidMount");
        // window.PopularWords.state.cur_word_index = saved_word_index;

        // const remainder = popular_word_dictionary.length % words_per_page;
        // full_word_list = popular_word_dictionary.slice(0, popular_word_dictionary.length - remainder);

        this.setState({"cur_word_index": saved_word_index});
    }

    render() {
        initialize(); //make sure the word list is initialized
        const {loading} = this.state;

        let div_words = <div id="div_words" className="div_words">
            {full_word_list.slice(this.state.cur_word_index, this.state.cur_word_index + words_per_page)
                .map((word, index) => (
                    <label key={word + index} id={word} className={getCSS_Class_Name(word)}
                           onClick={word_onClick}>{word}</label>
                ))}
        </div>

        const btn_pagination_classes = "button button_page button_vocabulary";

        return (

            <div id="div_popular_words" className="div_sub_page">
                <div className="div_settings_header">
                    <img className="button button_back" src={back} onClick={btnBack_onClick} alt="Back"/>
                    <label className="settings_header">17k most common words</label>
                </div>

                {loading ? <div className="div_vertical"><Spinner/></div> : <div></div>}

                {!loading ?
                    <div className="div_vertical" style={{marginBottom:"1em", marginTop: "2em"}}>
                        <label style={{marginBottom:"1em"}} className="label_text">Adjust the slider to the right until you encounter unfamiliar words, click them</label>
                        <div style={{display:"flex", flexDirection:"row", justifyContent: "space-between", width:"100%"}}>
                            <label className="label_text">Common words</label>
                            <label className="label_text">Rare words</label>
                        </div>

                        <div className="slider-wrapper">
                            <input type="range" id="sld_vocabulary_level" onChange={sdr_vocabulary_onChange}
                                   name="volume"
                                   min="0"
                                   max={full_word_list.length - words_per_page} value={this.state.cur_word_index}/>
                        </div>

                        <div id="div_controls" className="div_horizontal">
                            <button className={btn_pagination_classes} onClick={btnPrev_onClick}>&#60;</button>
                            <input id="inp_current_value" defaultValue={this.state.cur_word_index}
                                   className="input input_page_number" onKeyDown={onClick_Input_KeyDown} />
                            <button className={btn_pagination_classes} onClick={btnNext_onClick}>&#62;</button>
                        </div>
                        {div_words}
                    </div>
                    : <div/>
                }
            </div>
        )
    }
}

function initialize(){
    // log("PopularWords - initialize");
    // if(initialized || !popular_word_dictionary) return; 
    // else initialized = true;

    if(!popular_word_dictionary) return; // if there are no popular words - nothing to initialize

    const remainder = popular_word_dictionary.length % words_per_page;
    full_word_list = popular_word_dictionary.slice(0, popular_word_dictionary.length - remainder);

    // log("popular_word_dictionary.length", popular_word_dictionary.length);
}

export const btnAddPopularWords_onClick = async event => {
    log("btnAddPopularWords_onClick");

    initialize();

    await window.PopularWords.setState({loading: true});
    await showSubPage("div_popular_words");
    await loadSettings();
    await window.PopularWords.setState({loading: false});
    await setStartWord(true);
}

const btnBack_onClick = async event => {
    // saveSettings();
    await showSubPage('div_vocabulary');
    // await saveVocabulary();
}

async function saveSettings() {
    settings.last_popular_word.value = full_word_list[window.PopularWords.state.cur_word_index];
    log("PopularWords. saveSettings. last_popular_word: " + settings.last_popular_word.value);
    saveSettingsToDB(false);
}

async function loadSettings() {
    log("PopularWords. loadSettings");

    if(!settings.last_popular_word.value)
        await loadSettingsFromDB();

    if (settings.last_popular_word.value === "None" || settings.last_popular_word.value === "")
        return;

    let index = full_word_list.indexOf(settings.last_popular_word.value);
    log("indexOf: " + settings.last_popular_word.value + " = " + index);
    if (index !== -1)
        window.PopularWords.state.cur_word_index = index;
}

function getCSS_Class_Name(word, word_exist = null){
    const word_exist_class = "button vocabulary_word vocabulary_word_selected";
    const word_doesnt_exist_class = "button vocabulary_word vocabulary_word_not_selected";

    // We know if the word exist
    if(word_exist === true)
        return word_exist_class;
    if(word_exist === false)
        return word_doesnt_exist_class;

    // We learn if the word exist
    if(wordExist(word))
        return word_exist_class;
    else
        return word_doesnt_exist_class;
}

const word_onClick = async event => {
    log("word_onClick");
    // console.log("Clicked : " +  );
    const word = event.currentTarget.id;
    const button = event.currentTarget;
    const word_exist = wordExist(word);
    button.className = getCSS_Class_Name(word, !word_exist); // Preliminary change the button style

    if (word_exist)
        await removeWord(word);
    else
        await addWord(word);
    // button.className = getCSS_Class_Name(word); // Change the button style after the changes in the DB
    // window.PopularWords.setState({loading: false});
}

const onClick_Input_KeyDown = event => {
    // log("onClick_Input_KeyDown");

    if (event.key === 'Enter') {
        event.preventDefault();
        window.PopularWords.state.cur_word_index = parseInt(event.currentTarget.value);
        roundIndex();
        setStartWord();
    }
}

const btnNext_onClick = event => {
    // log("btnNext_onClick");

    window.PopularWords.state.cur_word_index = window.PopularWords.state.cur_word_index + words_per_page;
    setStartWord();
}

const btnPrev_onClick = event => {
    // log("btnPrev_onClick");

    window.PopularWords.state.cur_word_index -= words_per_page;
    setStartWord();
}

const sdr_vocabulary_onChange = event => {
    // log("sdr_vocabulary_onChange");

    window.PopularWords.state.cur_word_index = parseInt(event.currentTarget.value);
    roundIndex();
    setStartWord();
}

function roundIndex(){
    window.PopularWords.state.cur_word_index = Math.round(window.PopularWords.state.cur_word_index / words_per_page) * words_per_page;
}

function setStartWord(initialization = false){
    if(window.PopularWords.state.cur_word_index < 0)
        window.PopularWords.state.cur_word_index = 0;
    if(window.PopularWords.state.cur_word_index + words_per_page > full_word_list.length)
        window.PopularWords.state.cur_word_index = full_word_list.length - words_per_page;

    window.PopularWords.setState({"cur_word_index": window.PopularWords.state.cur_word_index});
    if(document.getElementById("inp_current_value") !== null)
        document.getElementById("inp_current_value").value = window.PopularWords.state.cur_word_index;

    // console.log("window.PopularWords.state.cur_word_index : " + window.PopularWords.state.cur_word_index);
    if(!initialization) saveSettings();
}
