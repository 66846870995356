import React from "react";
import {date_to_string, log} from "./helpers";
import {check_Auth_Locally} from "./google_sso_login";
import {request_and_validate} from "./request";
import {tryGetSetting} from "../sub_pages/settings";
import {Spinner} from "./spinner";
import common_scss from "../../scss/common.scss"

export let subscription_valid_until; // Example: 2023-04-15T15:59:04.367Z1683057011510
export let subscription_words_left;
export let subscription_credits;
let last_transaction_amount;
let last_transaction_date;
let last_transaction_status;

export default class Subscription_Info extends React.Component {

    constructor(props) {
        super(props);
        this.state = {update: false};
        window.Subscription_Info = this;
    }

    render() {
        const {loading} = this.state;
        let subscription_date_ms = Date.parse(subscription_valid_until);
        // if (debug_mode) console.log("subscription_valid_until (ms): " + subscription_date_ms);

        let date_color = common_scss.prim_color;
        let expired = subscription_date_ms < Date.now()
        let expired_display = "none";
        if (expired) {
            date_color = "red";
            expired_display="inline-block";
        }

        let words_color = common_scss.prim_color;
        if (subscription_credits <= 0) words_color = "red";

        let subscription_info;
        if (subscription_valid_until)
            subscription_info = <div>
                <label className="label" style={{marginTop: "40px"}}>Package</label><br/>
                <label>Valid until:</label><label style={{color: date_color}}> {date_to_string(subscription_valid_until)}</label>
                <label style={{display: expired_display,color: date_color}}>&nbsp;(expired)</label><br/>
                <label>Credits:</label><label style={{color: words_color}}> {subscription_credits} (~{subscription_words_left} words left)</label>
            </div>
        else
            subscription_info =
                <div>
                    <label>Please buy a package</label><br/>
                </div>

        let last_transaction_info = <div/>;
        if (last_transaction_amount)
            last_transaction_info =
                <div>
                    <label>Last payment: ${last_transaction_amount} from {date_to_string(last_transaction_date)}</label>
                    <label> (status: {last_transaction_status})</label>
                </div>

        return (

            <div style={{textAlign: "center"}}>
                {loading ?
                    <div className="div_vertical" style={{marginTop: "40px"}}><Spinner/></div>
                    :
                    <div style={{justifyContent: "left", alignItems: "left", textAlign: "left"}}>
                        {subscription_info}
                        {last_transaction_info}
                    </div>
                }

            </div>
        );
    }
}

export function reset_Subscription_Data(){
    subscription_words_left = null;
    subscription_credits = null;
    last_transaction_amount = null;
    last_transaction_date = null;
    last_transaction_status = null;
}

export async function get_subscription(){
    log("get_subscription");

    if (!check_Auth_Locally()) return false;
    await window.Subscription_Info.setState({loading: true});

    const response = await request_and_validate("subscription", "OPTIONS");
    if(!response) {
        await window.Subscription_Info.setState({loading: false});
        return false;
    }
    // log(response);

    if (!check_Auth_Locally()) {
        await window.Subscription_Info.setState({loading: false});
        return false;
    }

    subscription_valid_until = await tryGetSetting(response,"valid_until", true, 0);
    subscription_words_left = await tryGetSetting(response,"number_of_words", true, 0);
    subscription_credits = await tryGetSetting(response,"number_of_API_calls", true, 0);

    // log(response);
    // log("subscription_words_left:" + subscription_words_left)
    // log("subscription_credits:" + subscription_credits)

    //last payment details
    let subscription_payment = await tryGetSetting(response,"payment", true, 0);
    if(subscription_payment) {
        last_transaction_amount = await tryGetSetting(response, "amount", true, 1);
        last_transaction_date = await tryGetSetting(response, "date", true, 1);
        last_transaction_status = await tryGetSetting(response, "status", true, 1);
    }
    await window.Subscription_Info.setState({loading: false});
    return true;
}

