import React from "react";
import '../../scss/user_menu.scss';
import {JWT} from "../elements/google_sso_login";
import dictionary_icon from "../../images/Dictionary_mode_482_v2.png";
import brush_up_icon from "../../images/Brush_up_mode_482_v3.png";
import kids_icon from "../../images/Kids_mode_482_v2.png";
import {getButton_JSX} from "../buttons/mode_button";
import {settings} from "../sub_pages/settings";

export let div_menu_mode_id = "div_menu_mode";
export let btn_menu_mode_id = "btn_menu_mode";

export default class Menu_Mode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {menu_open: false};
        window.Menu_Mode = this;
    }

    render() {
        return (

            <div >
                {JWT ?
                    <div className='menu-container'>
                        <div id = {btn_menu_mode_id} className='menu-trigger' onClick={btnMenu_onClick}>
                            {getButton_JSX()}
                        </div>

                        <div id={div_menu_mode_id} style={{right:"20px"}}
                             className={`dropdown-menu ${window.Menu_Mode.state.menu_open ? 'active' : 'inactive'}`}>
                            <h3>App mode</h3>
                            <ul>
                                {/*<Menu_Item img={classic_mode_icon} text={settings.app_mode.modes.Default.title}*/}
                                {/*           name={settings.app_mode.modes.Default.name}  onClick={Menu_Item_onClick}/>*/}
                                <Menu_Item img={brush_up_icon} text={settings.app_mode.modes.Brush_up.title}
                                           name={settings.app_mode.modes.Brush_up.name} onClick={Menu_Item_onClick}/>
                                <Menu_Item img={dictionary_icon} text={settings.app_mode.modes.Dictionary.title}
                                           name={settings.app_mode.modes.Dictionary.name} onClick={Menu_Item_onClick}/>
                                <Menu_Item img={kids_icon} text={settings.app_mode.modes.Kids.title}
                                           name={settings.app_mode.modes.Kids.name} onClick={Menu_Item_onClick}/>
                            </ul>
                        </div>
                    </div>
                    :
                    <div>
                    </div>
                }
            </div>
        );
    }
}

const btnMenu_onClick = event => {
    window.Menu_Mode.setState({menu_open: !window.Menu_Mode.state.menu_open})
}

const Menu_Item_onClick = event => {
    switch (event.currentTarget.id) {
        // case "Classic":
        //     document.getElementById(settings.app_mode.modes.Default.control_name).click();
        //     window.Menu_Mode.setState({menu_open: false});
        //     break;
        case settings.app_mode.modes.Brush_up.title:
            document.getElementById(settings.app_mode.modes.Brush_up.control_name).click();
            window.Menu_Mode.setState({menu_open: false});
            break;
        case settings.app_mode.modes.Dictionary.title:
            document.getElementById(settings.app_mode.modes.Dictionary.control_name).click();
            window.Menu_Mode.setState({menu_open: false});
            break;
        case settings.app_mode.modes.Kids.title:
            document.getElementById(settings.app_mode.modes.Kids.control_name).click();
            window.Menu_Mode.setState({menu_open: false});
            break;
    }
}

export function Menu_Item(props){

    let _opacity = "0.7";
    let img_opacity = "0.4";
    if(settings.app_mode.value === props.name) {
        _opacity = img_opacity = "1";
    }

    return(
    <li className = 'dropdownItem' id={props.text} onClick={props.onClick} style={{listStyle:"none", opacity: _opacity}}>
            <img src={props.img} style={{opacity: img_opacity}}></img>
            <a style={{textDecoration:"none"}}> {props.text} </a>
        </li>
    );
}


