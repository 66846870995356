import { word_type, word_types } from "../main_page";
import { addQuotes, log, toss_a_coin, getRandomNumber, should_praise_user } from "../elements/helpers";
import { debug_mode } from "../app_settings";
// import {getQuestionIndex, question_list} from "../settings/content";
import { app_state, getQuestionIndex, learning_languages, settings } from "../sub_pages/settings";
import { learned_words } from "../elements/statistics";
import { balloons } from "balloons-js";

const Greetings = ["Hi!", "Hi there!", "Hi, thanks for inviting me", "Good evening!", "Good to be here!", "Hi, glad to be here"]
const Exclamations = ["Fascinating!", "That's interesting!", "Oh, I didn't know that", "Oh my gosh!", "That's amazing!"]
const Farewells = ["Thank you for inviting me!", "Thanks for having me!"]

let Questions;
let expression_local_copy;
let current_expression_quoted;
let question_list = ["Definition", "Examples", "Funny story", "Typical context", "Etymology", "Questions"]
let story_types = ["Funny", "Funny", "Interesting"]; //, "Spooky"

function init_Questions_for_Podcast() {
    log("initQuestions");

    //Check whether there is a new expression, otherwise - no need to redifine questions
    current_expression_quoted = addQuotes(app_state.expression);
    if (expression_local_copy === current_expression_quoted) return;

    Questions = [];
    // log("Definition: " + question_list[getQuestionIndex("Definition")])

    let definition_question = question_list[0];
    if ((settings.app_mode.value !== settings.app_mode.modes.Brush_up.name &&
        is_question_chosen(definition_question)) ||
        (settings.app_mode.value === settings.app_mode.modes.Brush_up.name && !app_state.old_word_mode &&
            is_question_chosen(definition_question)))
        Questions.push(getQuestion_by_Name(definition_question));

    for (let i = 0; i < question_list.length; i++) {
        if (i === 0) continue; // skip first question
        if (is_question_chosen(question_list[i]))
            Questions.push(getQuestion_by_Name(question_list[i]));
    }

    expression_local_copy = app_state.expression
}

export function getQuestion_by_Name(question_name) {
    // log("getQuestion_by_Name " + current_expression_quoted);

    current_expression_quoted = addQuotes(app_state.expression); // Question can be queried by additional buttons

    switch (settings.learning_language.value) {
        case learning_languages.en_US:
            return getEngQuestion(question_name);
            break;
        case learning_languages.ru_RU:
            return getRusQuestion(question_name);
            break;
        case learning_languages.fr_FR:
            return getFrenchQuestions(question_name);
            break;
    }
}

//Get question text by its title
function getEngQuestion(question_name) {
    let request_to_AI;
    let show_to_User;
    switch (question_name) {

        //used by default with the play button
        case "Examples":
            if (toss_a_coin(0.3))
                request_to_AI = "Tell a very short " + get_random_story_type() + " story with the " + word_type + " " + current_expression_quoted + ".";
            else
                request_to_AI = "Give us 1 sentence with the " + word_type + " " + current_expression_quoted + ".";

            log("learned_words", learned_words.length);
            if (should_praise_user()){
                request_to_AI += " " + get_praise_for_studying();
                balloons(); //congratulation
            }
            request_to_AI += " Ask a follow up question with the " + word_type + " " + current_expression_quoted;
            // show_to_User = "Would you give us an example of using the " + word_type + " " + app_state.expression + "?";
            show_to_User = "";
            break;

        case "Definition":
            request_to_AI = "What is the definition of the " + word_type + " " + current_expression_quoted + "?";
            show_to_User = request_to_AI;
            break;

        case "Funny story":
            request_to_AI = "Tell a very short humorous story with the " + word_type + " " + current_expression_quoted + ".";
            show_to_User = "I was wondering if you could tell us a tiny humorous story with the " + word_type + " " + app_state.expression + ".";
            break;

        case "Typical context":
            request_to_AI = "What is the typical context of the " + word_type + " " + current_expression_quoted + "?";
            show_to_User = request_to_AI;
            break;

        case "Etymology":
            request_to_AI = "How did the " + word_type + " " + current_expression_quoted + " come about?";
            show_to_User = request_to_AI;
            break;

        case "Questions":
            request_to_AI = "Come up with a question with the " + word_type + " " + current_expression_quoted;
            show_to_User = "Please answer the questions out loud using the " + word_type + " " + app_state.expression;
            break;
    }
    return [request_to_AI, show_to_User];
}

export function get_praise_for_studying() {
    return "Praise me for studying " + learned_words.length + " words today.";
}

function get_random_story_type() {
    const randomIndex = Math.floor(Math.random() * story_types.length);
    return story_types[randomIndex];
}

function getFrenchQuestions(question_name) {
    let request_to_AI;
    let show_to_User;

    let int_word_type = word_type;
    if (word_type === word_types.word)
        int_word_type = "du mot";
    else if (word_type === word_types.expression)
        int_word_type = "de l'expression";

    switch (question_name) {
        case "Definition":
            request_to_AI = "Quelle est la définition " + int_word_type + " " + current_expression_quoted + "?";
            break;

        case "Examples":
            request_to_AI = "Donnez-nous 2 exemples d'utilisation " + int_word_type + " " + current_expression_quoted;
            break;

        case "Funny story":
            request_to_AI = "Raconte-moi une très courte histoire humoristique avec " + int_word_type + " " + current_expression_quoted + ".";
            break;

        case "Typical context":
            request_to_AI = "Quel est le contexte typique " + int_word_type + " " + current_expression_quoted + "?";
            break;

        case "Etymology":
            request_to_AI = "Comment est née " + int_word_type + " " + current_expression_quoted + "?";
            break;

        case "Questions":
            request_to_AI = "Come up with 2 questions with the " + int_word_type + " " + current_expression_quoted;
            break;
    }
    show_to_User = request_to_AI;
    return [request_to_AI, show_to_User];
}

function getRusQuestion(question_name) {
    let request_to_AI;
    let show_to_User;

    let int_word_type = word_type;
    if (word_type === word_types.word)
        int_word_type = "слово";
    else if (word_type === word_types.expression)
        int_word_type = "выражение";

    switch (question_name) {
        case "Definition":
            request_to_AI = "Каково значение " + int_word_type + " " + current_expression_quoted + "?";
            break;

        case "Examples":
            request_to_AI = "Приведи 1 пример использования " + int_word_type + " " + current_expression_quoted;
            break;

        case "Funny story":
            request_to_AI = "Расскажи очень короткую смешныую историю с " + int_word_type + " " + current_expression_quoted + ".";
            break;

        case "Typical context":
            request_to_AI = "В каком контексте обычно используется " + int_word_type + " " + current_expression_quoted + "?";
            break;

        case "Etymology":
            request_to_AI = "Как произошло " + int_word_type + " " + current_expression_quoted + " ?";
            break;

        case "Questions":
            request_to_AI = "Придумай вопрос с " + int_word_type + " " + current_expression_quoted;
            break;
    }
    show_to_User = request_to_AI;
    return [request_to_AI, show_to_User];
}

export function getRecommendation() {
    const _expression = addQuotes(app_state.expression);
    let phrases = [
        "It might be a good idea to come up with 3 your own sentences using the " +
        word_type + " " + _expression + " and say them out loud",
        "Would be great if you could formulate 3 sentences with the " +
        word_type + " " + _expression + " and pronounce them",
        "We can recommend constructing 3 sentences with the " +
        word_type + " " + _expression + " and saying them out loud",
    ]
    const index = getRandomNumber(phrases.length)
    const come_up_with_sentences = phrases[index];
    return come_up_with_sentences;
}

export function shuffleArray(array) {
    array = array.sort(function () {
        return Math.random() - 0.5;
    });
    // console.log(array)
    return array
}

function is_question_chosen(question_name) {
    const index = getQuestionIndex(question_name);
    if (index === -1) return false;
    else return settings.content.value[index][1];
}

export function getFarewell() {
    let index = getRandomNumber(Farewells.length)
    let phrase = Farewells[index]
    return phrase
}

export function reset_phrases() {
    expression_local_copy = null;
}

export function getQuestionNumber(word_type, expression) {
    init_Questions_for_Podcast();
    return Questions.length
}

export async function getQuestion(number) {
    init_Questions_for_Podcast();
    return Questions[number]
}

export function getGreeting() {
    let index = getRandomNumber(Greetings.length)
    let phrase = Greetings[index]
    // console.log(phrase)
    return phrase
}

export function getExclamation(probability) {
    let phrase = ""
    let number = getRandomNumber(100)
    if (number < probability) {
        let index = getRandomNumber(Exclamations.length)
        phrase = Exclamations[index]
    }
    return phrase
}
