import React from "react";
import star from "../../images/star_64.png";
import {JSON_to_FormData, log} from "./helpers";
import {get_loaded_words} from "../sub_pages/vocabulary";
import {JWT} from "./google_sso_login";
import {request_and_validate} from "./request";

let prefix = "img_learned_word_"
const maxStarNumber = 10;
export let learned_words;

export default class Statistics extends React.Component {

    constructor(props) {
        super(props);
        window.Statistics = this;
    }

    render() {
        // log("Statistics. Render");
        // const {loading} = this.state;

        let div_words;
        if (learned_words && learned_words.length > 0) {
            div_words =
                <div className="div_horizontal" style={{justifyContent: "center", alignItems: "center", marginLeft: "1em"}}>
                    {learned_words.map((word, index) => (
                        <img src={star} className="icon_menu_small" alt="star" title={word["word"]}
                             id={prefix + word["word"]} style={{display:getDisplay(index)}} key={prefix + index}/>
                    ))}

                    <label style={{color: "grey", marginLeft:"0.2em"}}> ({learned_words.length})</label>
                </div>
        }

        return (
            <div style={{justifyContent: "center", alignItems: "center"}}>
                {div_words}
            </div>
        )
    }
}

function getDisplay(index){
    if (index > maxStarNumber)
        return "none";
    else
        return "inline-block";
}

export async function show_statistics(){
    log("show_statistics");

    if(!JWT) return;

    const offset = new Date().getTimezoneOffset()
    // log( "time_zone_offset: "  + offset);
    let metadata = { "time_zone_offset": offset};
    let response = await request_and_validate("statistics", "POST", JSON_to_FormData(metadata));

    // log(response);
    if(!response) return false;

    learned_words = await get_loaded_words(response);
    // if(debug_mode) showNotification("Statistics. Number of words: " + learned_words.length);
    window.Statistics.setState({"updated": true});
}

export function resetStatistics(){
    learned_words = null;
    window.Statistics.setState({"updated": true});
}
