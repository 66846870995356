import React from 'react';
import {btn_Play_onClick, btn_Pause_onClick} from "../main_page";
import Pause_icon from "../../images/Pause_225.png";
import Play_icon from "../../images/Play_482.png";
import {settings} from "../sub_pages/settings";

export default class Bottom_Button extends React.Component {

    constructor(props) {
        super(props);
        // this.state = {loading: false};
        window.Pause_Button = this;
    }

    componentDidMount() {
    }

    render() {

        // let display_value;
        // {settings.show_bottom_button.value? display_value = "block": display_value = "none"}

        return (
            <div/>
            // <div className="div_bottom_button button_floating"  style={{textAlign: "center", display: display_value}} >
            //     <img id="btn_Pause_Bottom" style={{display:"none"}} src={Pause_icon} onClick={btn_Pause_onClick} className="icon_button" alt="Pause"/>
            //     <img id="btn_Start_bottom" src={Play_icon} onClick={btn_Play_onClick} className="icon_button" alt="Play"/>
            // </div>
        )
    }
}
