import React from "react";

export default class Privacy_Policy extends React.Component {

    render() {
        return (
            <div>
                <b>Privacy Policy</b><br/><br/>
                We do not “sell” the personal information of our users as the term “sale” or “sell” is defined
                in the California Consumer Protection Act (“CCPA”). In other words, we do not sell, or disclose
                your personal information in exchange for money or something else of value.<br/><br/>
            </div>
        )
    }
}
