import React from "react";
import {debug_mode} from "../app_settings";
import back from "../../images/back.svg";
import {log, showSubPage} from "../elements/helpers";
import Refund_Policy from "../policies/refund";
import Delivery_Policy from "../policies/delivery";
import Privacy_Policy from "../policies/privacy";
import Terms_of_Service from "../policies/terms";
import Contact_Info from "../payment/contacts";

export default class Policies extends React.Component {

    render() {
        return (
            <div id="div_policies" className="div_sub_page">
                <div className="div_settings_header">
                    <img className="button button_back" src={back} onClick={btnBack_onClick} alt="Back"/>
                    <label className="settings_header">Policies</label>
                </div>

                <div style={{textAlign: "justify"}}>

                    <br/><br/>
                    <Terms_of_Service/>

                    <br/>
                    <Privacy_Policy/>

                    <br/>
                    <Refund_Policy/>

                    <br/>
                    <Delivery_Policy/>

                    <br/>
                    <Contact_Info/>

                    <br/>
                </div>
            </div>
        )
    }
}


export const btnOpenTerms_onClick= async event => {
    log("btnOpenTerms_onClick");
    await showSubPage("div_policies");
}

const btnBack_onClick = async event => {
    await showSubPage("div_main_content");
}

