import React from 'react';
import "../../scss/radial_buttons/radial_btn_buttons.scss"
import { say_the_word, stop_Activities, translateWord, update_Controls, word_type } from "../main_page";
import { add_tts_parameters_to_json, addQuotes, delay, JSON_to_FormData, log, request_types, setDisplayValue, showNotification } from "../elements/helpers";
import { loadImages } from "../elements/gallery";
import { getQuestion_by_Name } from "../resources/phrases";
import {
    getButton_Array
} from "./links_static";
import { radio_buttons_reset } from "./radial_button";
import { app_state, settings } from "../sub_pages/settings";
import { translation_languages } from "../resources/translation_languages";
import { ask_AI_and_pronounce, clean_response, open_ai_chat_model } from "../elements/APIs";
import { guest_says } from "../elements/tts";
import { request_and_validate } from '../elements/request';
import Vocabulary, { btn_Open_Vocabulary_onClick, get_words_for_crossword } from '../sub_pages/vocabulary';
import { set_Text_to_Translate } from '../elements/translation_block';

// link gallery options
export let links_images = "links_images"; // links to images for the gallery
export let links_dictionaries = "links_vocabularies"; // links to images for the gallery
export let links_practice = "links_bottom"; // links to dictionaries, etc. at the bottom
export let ai_buttons = "ai_buttons"; // links to dictionaries, etc. at the bottom

let button_array;
let container_id;
let style = {};
export let container_id_prefix = "div_link_container_";

export default class Link_Button extends React.Component {

    constructor(props) {
        super(props);

        switch (props.id) {
            case links_images:
                window.links_images = this;
                break;

            case ai_buttons:
                window.ai_buttons = this;
                break;

            case links_practice:
                window.links_practice = this;
                break;

            // case links_dictionaries:
            //     window.links_dictionaries = this;
            //     break;
        }
    }

    onClick() {
        log("onClick");
        let control = document.getElementById("radial");
        if (control.className === "radial open")
            control.className = "radial";
        else
            control.className = "radial open";
    }

    render() {

        let array = getButton_Array(this.props.id);
        button_array = array.button_array;
        container_id = array.container_id;
        style = array.style;


        return (
            <div>
                {getJSX()}
            </div>
        )
    }
}

export const btnLink_onClick = async event => {
    // let expression_in = addQuotes(expression);
    let expression_in = app_state.expression; //no quotes are needed
    log("btnLink_onClick: " + expression_in);
    let url;
    let ai_request;

    radio_buttons_reset();
    setDisplayValue("div_landing_page", "none"); //Hide the landing page

    switch (event.currentTarget.id) {
        case 'div_gifs':
            url = "https://www.google.com/search?q=" + expression_in + "+gif" + "&tbm=isch";
            // url = "https://giphy.com/search/" + expression;
            break;
        case 'div_images':
            url = "https://www.google.com/search?q=" + expression_in + "&tbm=isch";
            break;
        case 'div_load_images':
            loadImages();
            return;
        case 'div_say_the_word':
            guest_says(app_state.expression);
            return;
        case 'div_definition_ai':
            ai_request = getQuestion_by_Name("Definition")[0];
            await stop_activities_and_ask_ai(ai_request);
            return;
        case 'div_examples':
            ai_request = getQuestion_by_Name("Examples")[0];
            await stop_activities_and_ask_ai(ai_request);
            return;
        case 'div_sensual':
            ai_request = "Give me a sensual sentence with the " + word_type + " " + addQuotes(app_state.expression);
            // ai_request = "Give me a very short love story with the " + word_type + " " + addQuotes(app_state.expression);
            await stop_activities_and_ask_ai(ai_request);
            return;
        case 'div_context':
            ai_request = "What is the typical context the " + word_type + " " + addQuotes(app_state.expression) + " is used in?";
            await stop_activities_and_ask_ai(ai_request);
            return;
        case 'div_shocking':
            ai_request = "Give me a very short shocking story with the " + word_type + " " + addQuotes(app_state.expression);
            await stop_activities_and_ask_ai(ai_request);
            return;
        case 'div_scary':
            ai_request = "Give me a sentence with the " + word_type + " " + addQuotes(app_state.expression);
            await stop_activities_and_ask_ai(ai_request);
            return;
        case 'div_etymology':
            ai_request = "How did the " + word_type + " " + addQuotes(app_state.expression) + " come about?";
            await stop_activities_and_ask_ai(ai_request);
            return;
        case 'div_funny_story':
            ai_request = getQuestion_by_Name("Funny story")[0];
            await stop_activities_and_ask_ai(ai_request);
            return;
        case 'div_questions':
            ai_request = getQuestion_by_Name("Questions")[0];
            await stop_activities_and_ask_ai(ai_request);
            return;
        case 'div_crossword':
            await create_crossword();
            return;
        case 'div_funny_images':
            url = "https://www.google.com/search?q=funny+" + expression_in + "&tbm=isch";
            break;
        case 'div_pronounce_practice':
            url = "https://www.google.com/search?q=pronunciation+of+" + expression_in;
            break;
        case 'div_definition_eng':
            url = "https://www.google.com/search?q=definition+of+" + expression_in;
            break;
        case 'div_pronounce_examples':
            url = "https://youglish.com/pronounce/" + expression_in + "/english/us?";
            break;
        case 'div_sentences':
            url = "https://www.google.com/search?q=sentences+with+" + expression_in;
            break;
        case 'div_gen_painting':
            url = "https://labs.openai.com/";
            break;

        // https://translate.google.com/?sl=en&tl=ru&text=temper
        case 'div_google_translate':
            url = "https://translate.google.com/?sl=en&tl=" + settings.native_language.value + "&text=" + expression_in;
            break;
        case 'div_cambridge_dictionary':
            url = "https://dictionary.cambridge.org/dictionary/english/" + expression_in;
            break;
        default:
            log("Error with the value " + event.currentTarget.id);
    }
    window.open(url);
}

async function stop_activities_and_ask_ai(ai_request) {
    await stop_Activities();
    translateWord();
    await ask_AI_and_pronounce(ai_request);
}

async function create_crossword() {

    update_Controls(app_state.states.story_ongoing);

    const words = await get_words_for_crossword(10);
    if (!words) {
        showNotification("Please add words to create a crossword", "error");
        update_Controls(app_state.states.none); // The story has been fully played
        btn_Open_Vocabulary_onClick();
        return;
    }

    let question = "Please give definitions of the following words strictly following the format: <word> <definition>";
    question += "\nwords:\n" + words;
    log("question", question);


    let metadata = {
        question: question,
        model: open_ai_chat_model,
        request_type: request_types.text_to_text,
        messages: [],
        max_response_length: 1000,
    };
    metadata = add_tts_parameters_to_json(metadata);
    let formData = JSON_to_FormData(metadata)

    showNotification("Words and definitions will be copied to the clipboard");
    const response = await request_and_validate("ask_ai", 'POST', formData);
    // app_state.AI_response_was_loaded = true;

    const response_metadata = response.headers['x-metadata'];
    log("response_metadata", response_metadata)
    let response_json = JSON.parse(JSON.parse(response_metadata));
    app_state.AI_response = await clean_response(response_json["AI_response"], true);
    app_state.AI_response = app_state.AI_response.replace(/[:=]/g, ""); //remove ":" and "=" signs
    set_Text_to_Translate(app_state.AI_response, false, 'Copy the last words from your vocabulary below to the crossword website and click "Finish" button - https://crosswordlabs.com');

    try { //Copy to clipboard doesn’t work if the page is not focused
        await navigator.clipboard.writeText(app_state.AI_response);
    }
    catch (error) { log(error.message)}
    log("AI_response", app_state.AI_response);

    // Open a new website
    await window.open('https://crosswordlabs.com', '_blank');

    update_Controls(app_state.states.none); // The story has been fully played
}

function getJSX() {
    // log("Links getJSX settings.radial_button.value: " + settings.radial_button.value)

    let JSX = <div />;
    if (!settings.radial_button.value)
        JSX =
            <div id={container_id} className="div_link_container" style={style}>
                {
                    button_array.map((button) => (
                        <div id={button.id} key={button.id} onClick={btnLink_onClick}
                            className="div_vertical div_link">
                            <img src={button.src} className="image" />
                            <label className="label_button">{button.text}</label>
                        </div>
                    ))
                }
            </div>

    return JSX
}
