import React from "react";

export default class Delivery_Policy extends React.Component {

    render() {
        return (
            <div>
                <b>Delivery Policy</b><br/><br/>
                Access to the software is provided right after payment processing has been finished which usually
                takes just a few seconds. Please reach out to us if you experience any issues with access or payment processing.<br/><br/>
            </div>
        )
    }
}
