/* eslint-disable */
// Disable Eslint errors, otherwise it complains that TwoPayClient is not defined (the class is loaded from 2C website)

import React from 'react';
import {delay, Google_Analytics_Event, JSON_to_FormData, log, setDisplayValue} from "../elements/helpers";
import {debug_mode, localSetup} from "../app_settings";
import {check_Auth_Locally, JWT, JWT_decoded, user_id_in_DB} from "../elements/google_sso_login";
import {request_and_validate} from "../elements/request";
import {tryGetSetting} from "../sub_pages/settings";
import axios from "axios";
import {Spinner} from "../elements/spinner";
import {run_Subscription_monitoring} from "../sub_pages/payment";

let script_initialized = false;
let loading = false;

//location based on the user's ip address
let country;
let country_code;
let city;
let postal_code;
let customer_ip;

let jsPaymentClient;
let component;
let redirect_url_success = 'https://www.english-ai.org/?page=div_settings';
let redirect_url_failure = 'https://www.english-ai.org/?page=div_payment&status=failure';

const statuses  = {
    Reset: 'Reset',
    Loading  : 'Loading',
    Loaded     : 'Loaded',
};

export default class Checkout_form extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: true};
        window.Checkout_form = this;
    }

    async componentDidMount() {
        await initialize();
        // await window.Checkout_form.setState({loading:false}) //Reload the form, otherwise 2Checkout controls don't show up
        // await window.Checkout_form.setState({loading:false}) //Reload the form, otherwise 2Checkout controls don't show up
    }


    render() {

        let test_data;
        {
            debug_mode ?
                test_data = <div>
                    {/*<h5 className="card-title">Test data</h5>*/}
                    <p style={{fontSize:"12", color:"gray"}}>Ales Semchenkov - 5488880257487467 - 04/26 - 123 (Test data) </p>
                </div>
                :
                test_data = <div></div>
        }

        return (

            <div>
                <div className="container">
                    {test_data}

                    <form type="post" className="card_form" id="payment-form">
                        <div className="form-group" style={{paddingRight: "25px", paddingBottom: "14px"}}>
                            <label htmlFor="name" className="label_name">Name</label>
                            {/*<input type="text" id="name" className="field form-control" placeholder="Tom Cruise"/>*/}
                            <input type="text" id="name" className="field_name" placeholder="Tom Cruise"/>
                        </div>

                        <div id="card-element" style={{fontSize: "16px"}}>
                            {/*  2Checkout fields will appear here  */}
                        </div>

                        {/*<button className="btn btn-primary" type="submit">Pay</button>*/}
                        <div id="div_btn_pay" className="button-container" style={{margin: "9px 2px 18px"}}>
                            <input type="submit"
                                   className="pay-button"
                                   value="Pay" id="submit" onClick={btn_Pay_clicked}/>
                        </div>
                    </form>
                </div>
                <div style={{display: "none"}} id="spr_payment_loading" className="div_vertical">
                    <Spinner/>
                </div>
                <div style={{display: "block"}}>
                    <label id="lbl_payment_error"
                           style={{color: "red", marginTop: "2em", textAlign: "justify"}}></label>
                </div>

                <div className="additional_info" style={{marginTop: "2em"}} >
                    <label id="lbl_location_info"></label>
                </div>
            </div>
        )
    }
}

////////////////////// INITIALIZATION /////////////////////////////////

export async function load_2Checkout_script(){
    if(script_initialized) return;

    // Load 2Checkout script with TwoPayClient class
    // Should be loaded a second before use of TwoPayClient
    const script = document.createElement("script");
    script.src = "https://2pay-js.2checkout.com/v1/2pay.js";
    script.async = false;
    // script.defer = true;
    document.body.appendChild(script);
    await delay(1); //0.1 is enough, buy for slower PCs
    script_initialized = true;
}

async function initialize() {
    log("initialize Checkout_form");
    if (loading) return;
    else loading = true;

    // Initialize the 2Pay.js client.
    await load_2Checkout_script();
    const merchant_code = '254311099161';
    jsPaymentClient = new TwoPayClient(merchant_code);

    component = jsPaymentClient.components.create('card'); // Create the component that will hold the card fields.
    document.getElementById('card-element').innerHTML = ""; // preventing card field duplicates
    component.mount('#card-element'); // Mount the card fields component in the desired HTML tag. This is where the iframe will be located.

    // Handle form submission.
    // document.getElementById('payment-form').addEventListener('submit', btn_Pay_clicked);
    if(JWT) document.getElementById('name').value = JWT_decoded["name"]; // Fill in the name field
    // set_Control_status(statuses.Loaded);

    await load_geolocation();

    loading = false;
    Google_Analytics_Event("Payment", "Payment page was open (initialized)");
}

////////////////////// PAYMENT /////////////////////////////////

const btn_Pay_clicked = async event => {
    Google_Analytics_Event("Payment page", "Pay button clicked");
    if (debug_mode) console.log("btn_Pay_clicked");
    event.preventDefault();
    set_Control_status(statuses.Loading);

    await request_token();

    set_Control_status(statuses.Loaded);
}

async function request_token(){
    // Extract the Name field value
    const billingDetails = {
        name: document.querySelector('#name').value
    };


    // Call the generate method using the component as the first parameter and the billing details as the second one
    jsPaymentClient.tokens.generate(component, billingDetails).then(async (response) =>
    {
        // console.log("result" + response);
        await process_token(response);
    }).catch((error) => {
        console.error("request_token error: " + error);
        show_error(error);
    });

    // let response = await jsPaymentClient.tokens.generate(component, billingDetails);
    // try{
    //     console.log("result" + response);
    //     await process_token(response);
    // }
    // catch(error){
    //     console.error("request_token error: " + error);
    //     show_error(error);
    // }
}

async function process_token(checkout_response){
    log("process_response");
    if (!check_Auth_Locally()) return false;
    set_Control_status(statuses.Loading);

    if(!checkout_response)
        show_error("No response from payment provider 2Checkout. Please try again");

    // log(checkout_response.token);
    const response = await initiate_payment(checkout_response.token);
    if(!response) return false;
    log(response);

    const order_status = await tryGetSetting(response,"order_status");
    const message = await tryGetSetting(response,"message");
    const secure_3D_URL = await tryGetSetting(response,"secure_3D_URL");
    const test_order = await tryGetSetting(response,"test_order");
    // const response_status = await tryGetSetting(response,"status");
    // const amount = await tryGetSetting(response,"amount");
    // const transaction_id = await tryGetSetting(response,"transaction_id");

    log("order_status: " + order_status + " message: " + message + " secure_3D_URL: " +
        secure_3D_URL + " test_order: " +  test_order);

    // show_error("");
    if(!order_status) {
        show_error(message);
        set_Control_status(statuses.Loaded);
        // window.alert(message);
        return true;
    }

    // if(!debug_mode)
        window.open(secure_3D_URL);

    run_Subscription_monitoring();
    set_Control_status(statuses.Loaded);
}

async function initiate_payment(token){
    log("make_payment");

    if(localSetup) {
        redirect_url_success = 'http://localhost:3000/?page=div_settings'
        redirect_url_failure = 'http://localhost:3000/?page=div_payment&status=failure'
    }

    // await load_geolocation();
    const metadata = {
        token: token,
        //Real product $4.99 - J8VVVMQFYF  || Test product $0.75 - 39T1MYFE08
        product_code: 'J8VVVMQFYF', // Product code from the console - https://secure.2checkout.com/cpanel/products.php
        email: JWT_decoded["email"],
        name: JWT_decoded["given_name"],
        surname: JWT_decoded["family_name"],
        redirect_url_success: redirect_url_success,
        redirect_url_failure: redirect_url_failure,
        country_code: country_code,
        city: city,
        postal_code: postal_code,
        user_ip: customer_ip,
        user_google_id: JWT_decoded["sub"],
        user_id_in_DB: user_id_in_DB,
    };

    //const response = await request_and_validate("pay_checkout", "OPTIONS", metadata);
    const response = await request_and_validate("pay_checkout", "POST", JSON_to_FormData(metadata));
    return response;
}

async function load_geolocation() {
    log("load_geolocation");

    customer_ip = await getIP();
    const url = "https://api.ipregistry.co/" + String(customer_ip) + "?key=53yljhy62ejh888c";
    const res = await axios.get(url);
    if (!res) return;

    try {
        country = res.data["location"]["country"]["name"];
        country_code = res.data["location"]["country"]["code"];
        if(!postal_code) country_code = "GE" // The field code can't be empty
        city = res.data["location"]["city"];
        if(!postal_code) city = "Tbilisi" // The field code can't be empty
        postal_code = res.data["location"]["postal"];
        if(!postal_code) postal_code = "0160" // Zip code can't be empty
        show_geo_onfo("Country: " + country, " City: " + city)

    } catch (error) {
        country_code = null;
        city = null;
        console.error(error);
        show_error(error);
    }

    // if (debug_mode) console.log(JSON.stringify(res));
    // API call example:
    // https://api.ipregistry.co/87.253.36.148?key=53yljhy62ejh888c
}

async function getIP(){
    const res = await axios.get("https://api.ipify.org/?format=json");
    return res.data.ip;
};

////////////////////// OTHER /////////////////////////////////

function show_error(text){
    text = text.toString();
    // let _text = text.toString();
    log("show_error: " + text);

    if(text.includes("code 400"))
        text = "Please make sure the card data is correct and doesn't contain any spaces before or after the text" + '<br/>' + text;

    const lbl_payment_error = document.getElementById('lbl_payment_error');
    lbl_payment_error.innerHTML = text;
}

function set_Control_status(status){
    log("set_Control_status: " + status);

    switch(status) {
        // case statuses.Reset:
        //     show_error("");
        //     break;
        case statuses.Loaded:
            setDisplayValue('div_btn_pay', "block");
            setDisplayValue('spr_payment_loading', "none");
            break;
        case statuses.Loading:
            setDisplayValue('div_btn_pay', "none");
            setDisplayValue('spr_payment_loading', "flex");
            show_error("");
            break;
            // code block
            break;
        default:
        // code block
    }
}

function show_geo_onfo(country, city){
    const lbl_location_info = document.getElementById('lbl_location_info');
    lbl_location_info.innerHTML = country + '<br/>' + city;
}
