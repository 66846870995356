import React from 'react';
import dictionary_icon from "../../images/Dictionary_128.png";
import More_images_icon from "../../images/Multiple_pictures_400.png"
import pronunciation from "../../images/Pronunciation_128.png";
import video from "../../images/Video_277.png";
import youtube_icon from "../../images/Youtube_128.png";
import google_icon from "../../images/Google_128px.png";
import say_icon from "../../images/Speaker_512.png";
import funny_icon from "../../images/Funny_171.png";
import sensual_icon from "../../images/Sensual_171.png";
import shock_icon from "../../images/Shock_172.png";
import smart_icon from "../../images/Smart_171.png";
import question_icon from "../../images/Question_512.png";
import cambridge_icon from "../../images/Cambridge_dic_512.png";
import tellinng_icon from "../../images/Telling_173.png";
import smart_2_icon from "../../images/Etymology_171.png";
import ai_icon from "../../images/AI_512.png";
import fear_icon from "../../images/Fear_171.png";
import Dall_E from "../../images/Dall_E_512.png";
import image_icon from "../../images/Image_128.png";
import crossword_icon from "../../images/Crossword_256.png";

import {ai_buttons, links_images, links_practice} from "./link_button";

export const buttons_images = [
    { id: "div_load_images", src: More_images_icon,  text: "Images" },
    { id: "div_pronounce_examples", src: youtube_icon, text: "Videos"},
    { id: "div_images", src: google_icon,  text: "Images" },
    { id: "div_gifs", src: video, text: "GIFs"},
];

// export const buttons_dictionaries = [
//     { id: "div_definition_eng", src: dictionary_icon, text: "Definition"},
//     { id: "div_google_translate", src: google_icon, text: "Translate"},
//     { id: "div_say_the_word", src: say_icon, text: "Say"},
// ];

export const buttons_ask_ai = [
    { id: "div_etymology", src: smart_2_icon, text: "Etymology"},
    // { id: "div_examples", src: tellinng_icon, text: "Example"},
    { id: "div_definition_ai", src: tellinng_icon, text: "Definition"},
    { id: "div_context", src: smart_icon, text: "Context"},
    { id: "div_funny_story", src: funny_icon, text: "Funny"},
    { id: "div_sensual", src: sensual_icon, text: "Sensual"},
    { id: "div_shocking", src: shock_icon, text: "Shocking"},

    // { id: "div_scary", src: fear_icon, text: "Scary"},
    // { id: "div_questions", src: question_icon, text: "Questions"},
];

export const buttons_practice = [
    { id: "div_cambridge_dictionary", src: cambridge_icon, text: "Dictionary"},
    { id: "div_definition_eng", src: dictionary_icon, text: "Definition"},
    // { id: "div_google_translate", src: google_icon, text: "Translate"},
    { id: "div_say_the_word", src: say_icon, text: "Say"},
    { id: "div_pronounce_practice", src: pronunciation, text: "Pronunciation"},
    { id: "div_crossword", src: crossword_icon, text: "Crossword"},
    // { id: "div_gen_painting", src: Dall_E, text: "Generate a painting"},

];

export function getButton_Array(group){
    let button_array;
    let container_id;
    let style = {};
    let group_name;
    let position;
    let radial_style;
    let container_id_prefix = "div_link_container_";

    switch (group){
        case links_images:
            button_array = buttons_images;
            container_id = container_id_prefix + links_images;
            style = {marginTop: "0em"}
            group_name = "Image";
            position = "left";
            radial_style = {marginLeft: "150px", marginTop: "30px"}
            break;

        case ai_buttons:
            button_array = buttons_ask_ai;
            container_id = container_id_prefix + ai_buttons;
            style = {marginTop: "1em"}
            group_name = "Story";
            position = "center";
            radial_style = {marginLeft: "150px", marginTop: "30px"}
            break;

        case links_practice:
            button_array = buttons_practice;
            container_id = container_id_prefix + links_practice;
            style = {marginTop: "1em"}
            group_name = "Link";
            position = "right";
            radial_style = {marginLeft: "280px", marginTop: "30px"}
            break;

        // case links_dictionaries:
        //     button_array = buttons_dictionaries;
        //     container_id = container_id_prefix + links_dictionaries;
        //     style = {marginTop: "1em"}
        //     group_name = "Listen";
        //     position = "left";
        //     radial_style = {marginLeft: "30px", marginTop: "30px"}
        //     break;
    }

    return {button_array, container_id, style, group_name, position, radial_style};
}
