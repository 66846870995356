import React from 'react';
import {Google_Analytics_Event, log, setDisplayValue} from "../elements/helpers";
import {
    getButton_Array
} from "./links_static";
import {ai_buttons, btnLink_onClick, links_images, links_practice,} from "./link_button";
import {app_state, settings} from "../sub_pages/settings";
import {start_screen_lock, stop_screen_lock} from "../elements/screen_lock";
import {podcast_or_story_are_ongoing} from "../elements/podcast";
import radial_btn_scss from "../../scss/radial_buttons/radial_btn_buttons.scss"


let array;
let button_array;
let group_name;
let position;
export let radial_btn_names = ["left", "center", "right"];

export default class Radial_button extends React.Component {

    constructor(props) {
        super(props);
        // log("Radial_button. Constructor: " + props.id);

        switch (props.id) {
            case links_images:
                window.Radial_btn_links_images = this;
                break;

            case ai_buttons:
                window.Radial_btn_ai_buttons = this;
                break;

            case links_practice:
                window.Radial_btn_links_practice = this;
                break;

            // case links_dictionaries:
            //     window.Radial_btn_links_dictionaries = this;
            //     break;
        }
    }

    componentDidMount() {
    }

    render() {

        array = getButton_Array(this.props.id);
        button_array = array.button_array;
        group_name = array.group_name;
        position = array.position;
        // radial_style = array.radial_style;

        return (
            <div>
                {
                    settings.radial_button.value ?
                        // <div id={"radial_" + position} className="radial" style={radial_style}>
                            <div id={"radial_" + position} className={"radial"}>

                            <button id={position} className="fab" onClick={onClick} >{group_name}
                                {/* <div className="plus">
                                    <label className="img_radial">{group_name}</label>
                                </div> */}
                            </button>

                            {
                                button_array.map((button, index) => (
                                    <div id={button.id} key={button.id} onClick={btnLink_onClick}
                                         className={"fa fa_" + position + "-" + (index + 1)}>
                                        <img src={button.src} className="img_radial"/>
                                        <label className="label_button">{button.text}</label>
                                    </div>
                                ))
                            }

                        </div>
                        :
                        <div/>
                }
            </div>
        )
    }
}

const onClick = async event => {
    // log("Radial_button .onClick")
    Google_Analytics_Event("Radial button", "Radial button: " + event.currentTarget.id);
    let control = document.getElementById("radial_" + event.currentTarget.id);

    if (control.className === "radial open")
        control.className = "radial";
    else
        control.className = "radial open";

    let control_i;
    for (let i = 0; i < radial_btn_names.length; i++) {
        control_i = document.getElementById("radial_" + radial_btn_names[i]);
        

        if(radial_btn_names[i] === event.currentTarget.id) {
            log("index", radial_btn_scss.radial_button_z_index);
            control_i.style.zIndex = radial_btn_scss.radial_button_z_index;
            continue; // skip the clicked control
        }

        if(control.className === "radial open") {
            // the clicked button is opened
            control_i.className = "radial"; // close other buttons
            control_i.style.zIndex = "0"; // move back other buttons
        }
        else {
            // the clicked button is closed
            log("index", radial_btn_scss.radial_button_z_index);
            control_i.style.zIndex = radial_btn_scss.radial_button_z_index; // move forward other buttons
        }
    }
}

export async function radio_buttons_reset() {
    log("radio_buttons_reset");

    start_screen_lock(); //lock screen to close the button while switching to another tab (e.g. Google images)

    let control;
    for (let i = 0; i < radial_btn_names.length; i++) {
        control = document.getElementById("radial_" + radial_btn_names[i]);
        if (control) control.className = "radial";
        // resetControl(control);
    }

    if(!podcast_or_story_are_ongoing()) {
        stop_screen_lock(); // stop only if there is nothing playing
    }
}
