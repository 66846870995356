import React from 'react';
import back from "../../images/back.svg";
import delete_icon from "../../images/Delete_225_v2.png";
import {startVocabularyMode, translateWord} from "../main_page";
import {debug_mode, remind_rate} from "../app_settings";
import {check_Auth_Locally, JWT} from "../elements/google_sso_login";
import {btnAddPopularWords_onClick} from "./popular_words";
import {check_errors_in_response, request_and_validate, sendRequest} from "../elements/request";
import {btnAddWordsForm_onClick} from "./new_words";
import {Spinner} from "../elements/spinner";
import {delay} from "../elements/tts";
import {Google_Analytics_Event, JSON_to_FormData, log, showNotification, showSubPage} from "../elements/helpers";
import clipboard_icon from "../../images/Clipboard_240_v3.png";
import Log_in_label from "../elements/log_in_label";
import {load_control, makes_sense_to_load_again} from "../elements/load_control";
import {app_state} from "./settings";
const remove_btn_pref = "btn_remove_"
const put_off_btn_pref = "btn_put_off_"

let words_per_page = 7;
let page_count;
export let vocabulary_loaded = false;

// Word sorting order based on "practiced" attribute:
// 1. Unpracticed words (practiced is null), no sorting
// 2. Practiced words. Sorting: the oldest practiced word ... the most recent practiced word

export default class Vocabulary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {"words": []};
        window.Vocabulary = this;
    }

    componentDidMount() {
        // VocabLoginEvent();
    }

    btnNextPage_onClick = event => {
        if (window.Vocabulary.state.current_page < page_count - 1)
            this.setState({"current_page": window.Vocabulary.state.current_page + 1})
        // console.log(window.Vocabulary.state.current_page);
    }

    btnPrevPage_onClick = event => {
        if (window.Vocabulary.state.current_page > 0)
            this.setState({"current_page": window.Vocabulary.state.current_page - 1})
        // console.log(window.Vocabulary.state.current_page);
    }

    btnFirstPage_onClick = event => {
        this.setState({"current_page": 0})
    }

    btnLastPage_onClick = event => {
        this.setState({"current_page": page_count - 1})
    }

    put_off_Word_OnCLick = event => {
        const word_id = event.currentTarget.id.substring(put_off_btn_pref.length);
        const word = document.getElementById(word_id).innerHTML; //.trim();
        putOffWord(word);
    }

    removeWord_OnCLick = event => {
        log("removeWord_OnCLick")

        const word = event.currentTarget.id.substring(remove_btn_pref.length);
        removeWord(word);
        updateVocabState();
    }

    render() {
        const {loading} = this.state;

        let div_header =
            <div className="div_settings_header">
                <img alt="button back" className="button button_back" src={back} onClick={btnBack_onClick}/>
                <label className="settings_header">Vocabulary</label>
            </div>

        // Check whether we need to show pagination
        let div_pagination;
        const btn_pagination_classes = "button button_round button_page button_vocabulary";
        if (this.state && this.state.words && this.state.words.length > words_per_page) {
            div_pagination = <div id="div_pagination" className="div_horizontal" style={{margin: "0.9em 0em 0.5em"}}>
                <button className={btn_pagination_classes} onClick={this.btnFirstPage_onClick}>&#60;&#60;</button>
                <button className={btn_pagination_classes} onClick={this.btnPrevPage_onClick}>&#60;</button>
                <label
                    className="">{(this.state.current_page + 1) + " / " + page_count} </label>
                <button className={btn_pagination_classes} onClick={this.btnNextPage_onClick}>&#62;</button>
                <button className={btn_pagination_classes} onClick={this.btnLastPage_onClick}>&#62;&#62;</button>
            </div>
        }

        // Generate word buttons
        let div_words;
        const btn_put_off_classes = "button button_put_off button_vocabulary";
        const btn_vocabulary_classes = "button button_word button_vocabulary"

        if (this.state && this.state.words && this.state.words.length > 0) {
            let words_to_show = this.state.words.slice(this.state.current_page * words_per_page, (this.state.current_page + 1) * words_per_page);

            div_words = <div id="div_words" className="div_words" style={{alignItems: "initial"}}>
                <label style={{marginBottom: "1em"}} className="label_text">Click a word to study</label>

                {words_to_show.map((word, index) => (
                    // <label>{word["fields"]["word"]}</label>

                    <div className="div_horizontal" key={"div_" + index}>
                        <button id={put_off_btn_pref + word["word"]} key={put_off_btn_pref + index}
                                className={btn_put_off_classes} data-rh="tooltip 1"
                                onClick={this.put_off_Word_OnCLick}>put off
                        </button>
                        <button key={index} id={word["word"]} className={btn_vocabulary_classes}
                               onClick={word_onClick}>{word["word"]}</button>
                        <button id={remove_btn_pref + word["word"]} key={remove_btn_pref + index} className="button_remove"
                                onClick={this.removeWord_OnCLick} style={{justifyContent: "center", alignItems: "center"}} >
                            <img src={delete_icon} className="icon_menu_small" alt="Delete"/>
                        </button>
                    </div>
                ))}

                {div_pagination}
            </div>
        } else {
            div_words = <div id="div_words" className="div_words">
                <label style={{marginBottom: "1em"}}>Add words to study</label>
            </div>
        }

        return (
            <div id="div_vocabulary" className="div_sub_page">

                <div className="div_horizontal" style={{
                    justifyContent: "space-between", alignItems: "center",
                    marginBottom: "20px"
                }}>
                    {div_header}
                    {JWT ?
                        <div className="div_settings_header">
                            <img alt="Copy to Clipboard" className="button_copy button_secondary_shadow" src={clipboard_icon}
                                 onClick={btnCopyToClipboard_onClick}/>
                        </div>
                        :
                        <div/>
                    }
                </div>

                {loading ? <div className="div_vertical"><Spinner/></div> : <div></div>}
                <Log_in_label/>

                {!loading && JWT ? // data is loaded and the user is logged in
                    <div id="div_vocab_content" className="div_vertical">
                        {div_words}

                        <div className="div_vertical">
                            <button id="btn_Add_Popular_Words" style={{marginTop: "2em"}}
                                    className="button button_primary button_action_vocab_page"
                                    onClick={btnAddPopularWords_onClick}>Choose popular words
                            </button>
                            <button id="btn_Add_Words" style={{marginTop: "2em"}}
                                    className="button button_secondary button_action_vocab_page"
                                    onClick={btnAddWordsForm_onClick}>Add my words
                            </button>
                        </div>
                    </div>
                    :
                    <div></div>
                }
            </div>
        )
    }
}

///////////// EVENT HANDLERS

export const btn_Open_Vocabulary_onClick = async event =>{
    await window.Vocabulary.setState({loading: true});
    Google_Analytics_Event("Vocabulary", "Open vocabulary onClick");

    await showSubPage("div_vocabulary");
    await loadVocabulary();
    await window.Vocabulary.setState({loading: false});
}

const word_onClick = async event => {
    // log("event.currentTarget.innerHTML: " + event.currentTarget.innerHTML)
    const index = get_word_index(event.currentTarget.innerHTML);
    // log("index: " + JSON.stringify(window.Vocabulary.state.words[index]));
    await startVocabularyMode(window.Vocabulary.state.words[index]);
    btnBack_onClick();
}

export function word_was_practiced(word){
    const index = get_word_index(word);
    let practiced = false;
    if(index >= 0 && window.Vocabulary.state.words[index].practiced) practiced = true;
    log("word " + word + " was practiced: " + practiced)
    return practiced;
}

const btnBack_onClick = async event => {
    await showSubPage("div_main_content");
}

const btnCopyToClipboard_onClick = async event => {
    let clipboard = "word, created, practiced\n"
    for (let i = 0; i < window.Vocabulary.state.words.length; i++) {
        const word = window.Vocabulary.state.words[i];
        clipboard += word["word"] + ", " + word["created"] + ", " +  word["practiced"] + "\n";
    }

    await navigator.clipboard.writeText(clipboard);
    showNotification("Your vocabulary has been copied to the clipboard")
}

export async function get_words_for_crossword(number_of_words = 10){
    if(!vocabulary_loaded) await loadVocabulary();

    let last_words = "";
    if (number_of_words > window.Vocabulary.state.words.length)
        number_of_words = window.Vocabulary.state.words.length;

    // first words
    for (let i = 0; i < number_of_words; i++) {
        const word = window.Vocabulary.state.words[window.Vocabulary.state.words.length - i - 1]["word"]; //take last words from the vacabulary
        // const word = window.Vocabulary.state.words[i]["word"]; //take first words from the vacabulary
        if(containsMultipleWords(word))
            number_of_words += 1;
        else
            last_words += word + "\n";
    }

    return last_words;
}

function containsMultipleWords(str) {
    // Check if the input is a string before using trim
    log(str);
    if (typeof str !== 'string') {
        throw new TypeError('The input provided is not a string');
    }

    // Trim any extra spaces from the beginning and end, then split by spaces
    const words = str.trim().split(/\s+/);

    // Check if there is more than one word
    return words.length > 1;
}

const btnMoveWordsToTop_onClick = async event => {
    let number_of_words_to_move  = 10;

    if(window.Vocabulary.state.words.length < number_of_words_to_move)
        number_of_words_to_move = window.Vocabulary.state.words.length;

    // let last_word;
    for (let i = 0; i < number_of_words_to_move; i++) {
        // last_word = window.Vocabulary.state.words[-1];
        window.Vocabulary.state.words.unshift(window.Vocabulary.state.words.pop());
    }
    updateVocabState();
}

export async function setNextWord () {
    log("setNextWord");
    if (app_state.state === app_state.states.podcast_pausing) return true;

    if(!vocabulary_loaded) await loadVocabulary();
    if (window.Vocabulary.state.words.length === 0) return false; // No words in the dictionary

    // let index = get_word_index(word); // index of the current word
    // if (index !== -1) putOffWord(word);
    // putOffWord(word);

    let random_number = Math.floor(Math.random() * 100) // Range: 0 - 100 (%)
    let new_word;
    if(random_number > remind_rate) new_word = true;
    // log('random_number: ' + random_number);
    // log('remind_rate: ' + remind_rate);

    let next_word_obj;
    log("new_word: " + new_word);
    if(new_word)
        next_word_obj = window.Vocabulary.state.words[0];
    else
        next_word_obj = get_oldest_practiced_word();

    await startVocabularyMode(next_word_obj);
    log('Next word: ' + JSON.stringify(next_word_obj));
    return true;
}

function get_oldest_practiced_word(){
    log("get_oldest_practiced_word");
    // see the sorting logic on the top

    for (let i = 0; i < window.Vocabulary.state.words.length; i++) {
        let word = window.Vocabulary.state.words[i];
        if (word["practiced"] !== null) {
            if (debug_mode) console.log("word: " + JSON.stringify(word));
            return word;
        }
    }
    return window.Vocabulary.state.words[0]; // There are no unpracticed words
}

///////////// SAVE / LOAD VOCABULARY

export async function loadVocabulary() {
    if(!makes_sense_to_load_again(load_control.vocabulary.name)) return true;
    if (debug_mode) console.log("loadVocabulary");
    if (!check_Auth_Locally()) return false;

    // await delay(5);

    log("loadVocabulary");
    const response = await request_and_validate("vocabulary", "OPTIONS");
    if(!response) return false;
    load_control.vocabulary.last_load_time = Date.now();

    const loaded_words = await get_loaded_words(response);

    page_count = getPageCount(loaded_words.length, words_per_page);
    await window.Vocabulary.setState({"current_page": 0, "words": loaded_words});
    window.Vocabulary.state.words = loaded_words;
    vocabulary_loaded = true;

    // if (debug_mode) console.log("Loaded words from DB : " + loaded_words);
    return true;
}

export async function get_loaded_words(response){
    // log("get_loaded_words");

    const words_in = response.data;
    // log(response.data);

    let loaded_words = [];
    if (words_in && words_in !== "None") {
        for (let i = 0; i < words_in.length; i++) {
            let word = words_in[i]["fields"];
            let word_object = {word: word["word"], created: word["created"], practiced: word["practiced"]};
            loaded_words.push(word_object);
        }
        loaded_words = loaded_words.slice().reverse(); // Start with newly added words
        loaded_words = sortWords(loaded_words); // Sorting: put the latest practiced word to the end
    }

    return loaded_words;
}

export async function save_user_word(word) {
    log("save_user_word: " + word);
    if(!JWT) return false;

    if(!word) {
        console.error("The word " + word + " is not a valid word")
        return;
    }
    // if (last_word_from_vocabulary || !check_Auth_Quickly()) return false;

    if(!vocabulary_loaded) await loadVocabulary();
    if(!vocabulary_loaded) {
        // showNotification("Failed to load the vocabulary", "error");
        console.error("Failed to load the vocabulary")
        return false;
    }

    if(wordExist(word)) {
        log(word + " is already in the vocabulary")
        return true;
    }

    if(! await addWord(word)) {
        showNotification("Failed to add the word " + word, "error");
        return false;
    }

    log(word + " has been added to your vocabulary");
    showNotification(word + " has been added to your vocabulary")
    return true;
}

export async function addWord(word){
    if(!vocabulary_loaded) console.error("The vocabulary has not been loaded yet!");
    log("addWord");

    if(wordExist(word)) {
        log(word + " is already in the vocabulary")
        return true;
    }

    let word_object = {word: word, created: get_cur_date(), practiced: null};
    await save_Word_to_DB(word_object);
    await add_word_UI(word_object);
    return true;
}

export async function removeWord(word){
    if (debug_mode) console.log("removeWord");
    await remove_word_DB(word);
    await remove_word_UI(word, true);
}

export async function putOffWord(word, put_off_in_DB = true){
    if(debug_mode) console.log("putOffWord: " + word);
    if (get_word_index(word) === -1){
        // if(debug_mode) showNotification("The word " + word + " was not found in the vocabulary", "error")
        if(debug_mode) console.log("The word " + word + " was not found in the vocabulary");
        return false;
    }

    await putOff_word_UI(word);
    if(put_off_in_DB)
        await putOff_Word_DB(word); //await is needed, otherwise the words are not moved to the end
}

export async function save_Word_to_DB(word_obj){
    if(debug_mode) console.log("add_Word_DB");

    let metadata = JSON.stringify(word_obj);
    const response = await request_and_validate("vocabulary", "POST", JSON_to_FormData(metadata));
    if(!response) return false;

    if(debug_mode) console.log("The word has been saved: " + word_obj["word"]);
}

export async function remove_word_DB(word){
    console.log("removeWord");

    let metadata_json = JSON.stringify({word: word});
    const response = await request_and_validate("vocabulary", "DELETE", metadata_json);
    if(!response) return false;

    // if(await checkResponse(response))
    if(debug_mode) showNotification("The word has been removed: " +  word);
}

export async function putOff_Word_DB(word){
    // updates the "practiced" attribute to move the word to the end of the list
    log("putOff_Word_DB");

    let index = get_word_index(word);
    let word_obj = window.Vocabulary.state.words[index];
    word_obj["practiced"] = get_cur_date(); // Update the practice timestamp to move the word back

    let metadata = JSON.stringify(word_obj);
    // log("json_params: " + json_params);
    const response = await request_and_validate("vocabulary", "POST", JSON_to_FormData(metadata));
    if(!response) return false;

    log("The word has been updated: " + word_obj["word"]);
}

async function add_word_UI(word_object) {
    // if (debug_mode) console.log("add_word_UI: " + word_object["word"]);

    window.Vocabulary.state.words = [word_object].concat(window.Vocabulary.state.words); // Merge the new and old words
    updateVocabState();
    // if (debug_mode) console.log("word was added to UI: " + word_object);
    // if (debug_mode) console.log(window.Vocabulary.state.words);

    return true;
}

async function remove_word_UI(word) {
    if (debug_mode) console.log("remove_word_UI: " + word);

    const index = get_word_index(word);

    if (index === -1)
        if (debug_mode) console.log("word was not found: " + word);

    window.Vocabulary.state.words.splice(index, 1); // 2nd parameter means remove one item only
    if (debug_mode) console.log("word was removed from UI: " + word);

    updateVocabState();
    return true;
}

async function putOff_word_UI(word){
    // const index = window.Vocabulary.state.words.indexOf(word);
    const index = get_word_index(word);
    if (index === -1) { // only splice array when item is found
        return;
    }

    // Move the word to the end
    window.Vocabulary.state.words.push(window.Vocabulary.state.words.splice(index, 1)[0]);
    updateVocabState();
}

function sortWords(words){
    let unpracticed_words = [];
    let practiced_words = [];

    for (let i = 0; i < words.length; i++) {
        let word = words[i];
        // if(debug_mode) console.log("word: " + word["practiced"]);
        if (word["practiced"] === null)
            unpracticed_words.push(word);
        else
            practiced_words.push(word);
    }

    // if(debug_mode) console.log("unpracticed_words: " + JSON.stringify(unpracticed_words));
    // if(debug_mode) console.log("practiced_words: " + JSON.stringify(practiced_words));
    practiced_words = practiced_words.sort(function(a,b){return new Date(a["practiced"]) - new Date(b["practiced"]) });

    let sorted_words = unpracticed_words.concat(practiced_words);
    return sorted_words;
}

///////////// AUXILIARY FUNCTIONS

function get_cur_date(){
    return new Date().toJSON()
}

export function wordExist(word){
    const index = get_word_index(word);
    // log("wordExist: " + index)

    if(index !== -1)
        return true;
    else
        return false;
    // return window.Vocabulary.state.words.includes(word);
}

function get_word_index(word){
    // if(debug_mode) console.log("get_word_index: " + word)
    for (let i = 0; i < window.Vocabulary.state.words.length; i++) {
        if (window.Vocabulary.state.words[i]["word"] === word)
            return i;
    }
    return -1;
}

function getPageCount(item_count, items_per_page) {
    return Math.ceil(item_count/items_per_page);
}

// Update the controls after a change in the vocabulary
function updateVocabState() {
    window.Vocabulary.setState(window.Vocabulary.state.words);
    page_count = getPageCount(window.Vocabulary.state.words.length, words_per_page);
}
