// import {stopping_podcast} from "../main_page";
import { toast } from "react-toastify";
import { request_and_validate } from "./request";
import { app_state, settings } from "../sub_pages/settings";
import { debug_mode } from "../app_settings";
import { podcast_is_paused_or_pausing, podcast_or_story_is_pausing } from "./podcast";
import ReactGA from "react-ga4";
import { audio_context, guest_voice } from "./tts";
import { popular_word_dictionary } from "./autocmoplete";
import { btn_StopRecording_onClick } from "../elements/AudioRecorder";
import { learned_words } from "../elements/statistics";

// import {debug_mode} from "../app_settings";

// Toastify playground - https://fkhadra.github.io/react-toastify/introduction/
export const please_log_in_phrase = "Please log in";
// export let watchdogTimer;
export let recordingTimer;
let notificationTimer;
let last_notification_text;
let praised_for_n_words = 0; // number of words the user was praised for last time

export const request_types = {
    text_to_text: 'text_to_text',
    text_to_audio: 'text_to_audio',
    audio_to_audio: 'audio_to_audio',
};

// Watchdog for Safari (is it working? try to disable and test)
// export function startWatchdog() {
//     // return;

//     if (!is_iOS()) return; //only for Safari mobile
//     clearTimeout(watchdogTimer); // Clear any existing timer

//     // Set a new timer to execute an action after 4 seconds
//     watchdogTimer = setTimeout(() => {
//         log('Watchdog triggered after 4 seconds');
//         // log("app_state.AI_response_was_loaded: " + app_state.AI_response_was_loaded);

//         if (app_state.AI_response_was_loaded) {
//             app_state.audio_suspended = false;
//         }
//         else
//             app_state.audio_suspended = true;

//         log("app_state.audio_suspended: " + app_state.audio_suspended)
//         // You can place any action you want to perform here
//     }, 4000);
// }

// stop recording after X seconds
export function stop_Rec_In_X_seconds(seconds = 120) {
    clearTimeout(recordingTimer); // Clear any existing timer

    // Set a new timer to execute an action after X seconds
    recordingTimer = setTimeout(() => {
        log('stop_Rec_In_X_seconds triggered');
        if (app_state.audio_recording) btn_StopRecording_onClick();
    }, seconds * 1000);
}

export function should_praise_user() {
    // return true;

    if (learned_words.length === 3 || learned_words.length % 5 === 0) {
        if (praised_for_n_words === learned_words.length)
            return false;
        praised_for_n_words = learned_words.length;
        return true;
    }
    return false;
}

// returns yes or now, depends on probablility [0,1]
export function toss_a_coin(probability) {
    let random_number = Math.random();
    // log(random_number);
    if (probability >= random_number)
        return true
    else
        return false
}

//returns an unteger random number
export function getRandomNumber(max) {
    return Math.floor(Math.random() * max);
}

export function add_tts_parameters_to_json(json) {
    json["provider"] = guest_voice.provider;
    json["voice"] = guest_voice.voice;
    json["language"] = settings.learning_language.value;
    json["engine"] = guest_voice.engine;
    return json;
}

export function is_iOS() {
    let userAgent = window.navigator.userAgent;
    const iOS = /iPhone|iPad|iPod/.test(userAgent);
    // log("is_iOS", iOS);
    // log("userAgent", userAgent);
    return iOS;
}

export function JSON_to_FormData(json) {
    const formData = new FormData();
    formData.append('metadata', JSON.stringify(json));
    return formData;
}

export function get_Item_from_FormData(formData, element_name) {
    if (!(formData instanceof FormData))
        return null;
    let metadata_json_string = formData.get('metadata');
    let metadata_json = JSON.parse(metadata_json_string);
    let item = metadata_json[element_name]
    return item;
}

export function lang_code_long_to_short(language_code_long) {
    // fr-FR -> fr
    let language_code_short = language_code_long.substring(0, 2);
    return language_code_short;
}

export async function translate(text) {
    log("translate:", text);
    if (app_state.state === app_state.states.podcast_pausing) return;
    let metadata = { text: text, language: settings.native_language.value, learning_language: lang_code_long_to_short(settings.learning_language.value) };

    if (settings.native_language.value === lang_code_long_to_short(settings.learning_language.value)) {
        showNotification('Native and learning languages can\'t be the same', "error");
        return null;
    }

    const response = await request_and_validate("translate", "POST", JSON_to_FormData(metadata));
    // if (debug_mode) console.log(response);
    if (!response) return false;

    try {
        let translated_text = response.data['translatedText'];
        return translated_text;
    } catch (ex) {
        console.log("Parsing error" + ex)
        return null;
    }
}

export function showNotification(text, type = null, pop_up_time_ms = 3000) {

    // let element = document.getElementById("toastContainer");
    // element.toastStyle = { backgroundColor: "#fc0703", color: "white" };

    // Notification types
    // toast.info - green
    // toast.warn - yellow
    // toast.error - red
    // toast.dark - white

    const parameters = {
        position: toast.POSITION.BOTTOM_CENTER,
        closeOnClick: true,
        autoClose: { pop_up_time: pop_up_time_ms },
        hideProgressBar: false,
        theme: "colored",
    }

    if (last_notification_text === text) {
        // log("notification duplicate was blocked")
        return; //no need to show the same notification twice
    }
    else last_notification_text = text;

    switch (type) {
        case "error":
            toast.error(text, parameters);
            turn_off_notification(pop_up_time_ms);
            break;

        // case "authorization error":
        //     text = "Please log in with a Google account";
        //     toast.error(text, parameters);
        //     break;

        case "info":
            toast.dark(text, parameters);
            turn_off_notification(pop_up_time_ms);
            break;

        default:
            toast.info(text, parameters);
            turn_off_notification(pop_up_time_ms);
            break;
    }
}

// stop recording after X seconds
export function turn_off_notification(milliseconds) {
    clearTimeout(notificationTimer); // Clear any existing timer

    // Set a new timer to execute an action after X seconds
    notificationTimer = setTimeout(() => {
        log('turn_off_notification triggered');
        last_notification_text = null; //reset the last notification
    }, milliseconds);
}

export function date_to_string(date) {
    const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    };
    if (date)
        return new Date(date).toLocaleString('en-GB', options);
    else
        return null;
}

export async function delay(time_sec) {
    const target = time_sec;
    const step = 0.1; // sec
    let time_past = 0; //sec

    let waiting_time = step;
    if (target < step)
        waiting_time = target;

    while (true) {
        await new Promise(res => setTimeout(res, waiting_time * 1000));
        time_past += step;

        if (time_past >= target) return;
        if (podcast_or_story_is_pausing()) return;
    }
}

export function setDisplayValue(element_name, value) {
    const element = document.getElementById(element_name);
    if (element)
        element.style.display = value;
}

export async function showSubPage(control_name) {
    if(!control_name) return;
    
    log("showSubPage", control_name);

    // Disable all pages
    // Main page
    setDisplayValue("div_main_content", "none");
    // Sub pages
    setDisplayValue("div_popular_words", "none");
    setDisplayValue("div_new_words", "none");
    setDisplayValue("div_vocabulary", "none");
    setDisplayValue("div_settings", "none");
    setDisplayValue("div_payment", "none");
    setDisplayValue("div_menu", "none");
    setDisplayValue("div_policies", "none");
    setDisplayValue("div_contact_us", "none");

    //Hide top menu if a sub page is shown
    if (control_name === "div_main_content")
        setDisplayValue("div_top_menu", "block");
    else
        setDisplayValue("div_top_menu", "none");

    // Show only 1 page
    setDisplayValue(control_name, "block");


    if (!document.getElementById(control_name)) // If the page doesn't exist -> show the main page
        setDisplayValue("div_main_content", "block");
}

export function removeQuotes(word) {
    return word.replace(/"+/g, '');
}

export function addQuotes(word) {
    // log("addQuotes: " + word);
    if (is_multiple_words(word) && app_state.expression[0] !== "\"")
        word = "\"" + word + "\"";
    // log("output: " + word);
    return word;
}

export function is_multiple_words(word) {
    if (word.split(" ").length === 1)
        return false;
    else
        return true;
}

export async function getLangNameByCode(languages, language_code) {
    // log("getLangNameByCode: " + language_code)

    for (const language of languages) {
        if (language["code"] === language_code) {
            return language["name"];
        }
    }
    return null;
}

export function log_time(name = "time stamp") {
    log(name, new Date() + " ms: " + new Date().getMilliseconds());
}

export function log(name, value = "*") {
    if (debug_mode) {
        if (value !== "*")
            console.log(name + ": " + value);
        else
            console.log(name);
    }
}

export async function getLangCodeByName(languages, language_name) {
    // log("language_name: " + language_name);

    for (const language of languages) {
        if (language["name"] === language_name) {
            return language["code"]
        }
    }
    return null;
}

export function showWord_Frequency() {
    if (!app_state.expression || settings.app_mode.value === settings.app_mode.modes.Kids.name || !settings.show_word_frequency.value) return;
    const index = popular_word_dictionary.indexOf(app_state.expression);
    if (index < 0) return;

    // const rounded_word_number = Math.round(popular_word_dictionary.length / 1000) * 1000
    let text = "Word's frequency position: " + index + ' / ' + roundToThousands(popular_word_dictionary.length);
    log(text);
    showNotification(text, "info");
}

export function roundToThousands(number) {
    return Math.round(number / 1000) * 1000;
}

export function Google_Analytics_Event(page, action) {

    ReactGA.event({
        category: page,
        action: action,
        // label: "your label", // optional
        // value: 99, // optional, must be a number
        // nonInteraction: true, // optional, true/false
    });
}

// const useAnalyticsEventTracker = (category="Blog category") => {
//     const eventTracker = (action = "test action", label = "test label") => {
//         ReactGA.event({category, action, label});
//     }
//     return eventTracker;
// }
// export default useAnalyticsEventTracker;
