import React from 'react';
import {Open_Payment_Page} from "../sub_pages/payment";

export default class Payment_Page_Button extends React.Component {

    componentDidMount() {
        window.Payment_Page_Button = this;
    }

    render() {

        return (
            <div className="div_pay_button" style={{textAlign: "center"}} >
                <button id="pay_button" className="button_common button button_action button_primary " onClick={Open_Payment_Page}>Buy package</button>
            </div>
        )
    }
}
