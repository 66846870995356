import axiosRetry from "axios-retry";
import axios from "axios";
import {criticalError} from "../main_page";
import {server_URL} from "../app_settings";
import {JWT} from "./google_sso_login";
import {delay, get_Item_from_FormData, log, request_types, showNotification} from "./helpers";
import {check_errors_in_response} from "./error_handler";
import {app_state} from "../sub_pages/settings";
import audiobufferToBlob from "audiobuffer-to-blob";

let controller = new AbortController();
let request_aborted = false;
// Axios examples: https://masteringjs.io/tutorials/axios/options

//example
// let json_params = {"text": text, "provider": provider, "voice": voice};
// let config_params = { "responseType": 'arraybuffer'};
// const resp = await sendRequest("tts", 'OPTIONS', false,  json_params, config_params, waiting_time);

export async function request_and_validate(path, method, data= null){
    log("request_and_validate");

    const response = await sendRequest(path, method, data);
    // log(response);

    if(path === "subscription") {
        // log("enter check_response_errors");
        if (!await check_errors_in_response(response, "ignore_subscription_error")) return null;
    }
    else {
        if (!await check_errors_in_response(response)) return null;
    }
    // log(response);
    return response;
}

async function sendRequest(path, method, data= null) {
    // log("sendRequest")

    if (!data) data = {};
    let content_type = 'multipart/form-data';
    controller = new AbortController();

    let request_config = {
        url: server_URL + "/" + path,
        headers: {
            'Content-Type': content_type,
            'Accept': 'application/json',
            'Authorization' : 'Bearer ' + JWT,
        },
        method: method,
        data: data,
        signal: controller.signal,
    }
    // change the response type for requests with file (audio) response
    let request_type = get_Item_from_FormData(data, "request_type");
    if(request_type === request_types.text_to_audio ||
        request_type === request_types.audio_to_audio ||
        path === "tts"
        // path === "ai_voice" ||
        // path === "voice"
    )
        request_config["responseType"] = "arraybuffer";

    // log(request_type)
    let result = await getResponse_w_Retry(request_config);
    // log(result)
    return result;
}

// Manual retry implementation (axios retry send requests too fast)
async function getResponse_w_Retry(request_config) {
    log("getResponse_w_Retry");

    let response;
    let start_time = Date.now();
    let min_waiting_time = 4000; // default = 4000 ms = 4 s
    let max_waiting_time = 30; // [sec]

    // Adjust min waiting time for TTS requests
    if(request_config['url'].includes('tts')) {
        const metadata_json = JSON.parse(request_config["data"].get("metadata"));
        let min_waiting_time_for_tts = metadata_json["text"].length * 10; // ms, depends on the text length
        if(min_waiting_time_for_tts > min_waiting_time)
            min_waiting_time = min_waiting_time_for_tts;
    }

    let waiting_time = min_waiting_time;
    let retry_number = 0;

    while(true){
        if(app_state.state === app_state.states.podcast_pausing) return null;

        // Count retry attempts
        retry_number += 1;
        if(retry_number > 1) {
            showNotification("Server connection error", "error")
            console.log("Retry attempt #" + retry_number);
        }

        // Get response
        response = await getResponse(request_config, waiting_time, 0);

        if(!response && request_aborted){
            // log("request_aborted");
            request_aborted = false;
            return;
        }

        // Check response status and wait if necessary
        if ((!response || response.status !== 200)) {
            waiting_time = min_waiting_time * retry_number;
            await wait(waiting_time / 1000);
        }
        else
            return response; // Response is OK

        // Stop attempts after max duration, sec
        let processing_time_sec = (Date.now() - start_time) / 1000;

        if (processing_time_sec > max_waiting_time) {
            // console.log();
            let message = "No response from the server in the course of " + processing_time_sec + " seconds";
            console.error("Endpoint : " + request_config["url"]);
            await criticalError(message);
            throw new Error(message);
            return null;
        }
    }
}

async function wait(waiting_time){
    for (let i = 0; i < waiting_time; i++) {
        if (app_state.state === app_state.states.podcast_pausing) return;
        await delay(1); // wait 1s
    }
}

async function getResponse(config, waiting_time, retries_num = 3) {
    log("getResponse");

    axiosRetry(axios, {
        retries: retries_num, // number of retries
        retryDelay: (retryCount) => {
            // console.log('TTS retry attempt: ${retryCount}`);
            return retryCount * waiting_time; // time interval between retries in ms
        },
        retryCondition: (_error) => true, // retry no matter what
    });

    const resp = await axios(
        config
    ).catch((err) => {
        // const message = "API call to " + config.url + " failed after a few retry attempts" ;
        console.error(err);
        log("getResponse. err.name: " + err.name);
        if(err.name === "CanceledError")
            request_aborted = true;

        // criticalError(message);
        // throw new Error(message);
    });

    return resp;
}

export async function stopRequests(){
    log("stopRequests");
    //There might be a few requests running in parallel -> we need to abort all of them
    await controller.abort();
    await controller.abort();
    await controller.abort();
    await controller.abort();
    await controller.abort();
}
