// Example - https://www.educative.io/answers/how-to-keep-your-screen-awake-using-javascript
// Documentation - https://developer.mozilla.org/en-US/docs/Web/API/Screen_Wake_Lock_API

import {log} from "./helpers";
import {debug_mode} from "../app_settings";

let screenLock;

// prevent the device's screen from turning off
export async function start_screen_lock() {
    try{
        if(isScreenLockSupported()){
            try {
                // log("screenLock launched");
                screenLock = await navigator.wakeLock.request('screen');
            } catch(err) {
                log(err.name, err.message);
            }
        }
    }
    catch (e) {
        log(e);
    }
}

export function stop_screen_lock() {
    try {
        if(typeof screenLock !== "undefined" && screenLock != null) {
            screenLock.release()
                .then(() => {
                    // log("Lock released 🎈");
                    screenLock = null;
                });
        }
    }
    catch (e) {
        log(e);
    }
}

function isScreenLockSupported() {
    return ('wakeLock' in navigator);
}
