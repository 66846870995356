import React, { Component } from 'react';
import { add_tts_parameters_to_json, log, request_types, Google_Analytics_Event, stop_Rec_In_X_seconds, should_praise_user } from "./helpers";
import call_icon from "../../images/Call_512_v2.png";
import call_hang_up_icon from "../../images/Call_hang_up_512.png";
import { ask_AI_and_pronounce, askAI_voice, askAI_voice_new, open_ai_chat_model } from "./APIs";
import { app_state, max_ai_response_length, settings } from "../sub_pages/settings";
import { btn_key_control_secondary, btn_Pause_onClick, stop_Activities_reset_Controls, translateWord } from "../main_page";
import "../../scss/record_button.scss"
import { wait } from "@testing-library/user-event/dist/utils";
import { debug_mode } from "../app_settings";
import { guest_voice, setVoices } from "./tts";
import { Spinner, SpinnerBig } from "./spinner";
import { learned_words } from "./statistics"
// import RecordRTC from 'recordrtc';
import MicRecorder from 'mic-recorder-to-mp3';
import { get_praise_for_studying } from "../resources/phrases"
import { recordingTimer, clear_Timeout } from "./helpers";
import { balloons } from 'balloons-js';

//Pulse effect name - Sonar Wave Effect in Circle With CSS

// let audioData = [];
// let audioStream = null;
// let mediaRecorder = null;
// let RTCrecorder = null;
export const MP3recorder = new MicRecorder({
  bitRate: 128
});
const audio_format = "mp3"; //wav
//export let recording = false;

export default class AudioRecorder extends Component {

  constructor(props) {
    super(props);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  //To open a file and send it to backend (debug)
  async handleFileChange(event) {
    log('handleFileChange');

    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      await ask_AI_and_pronounce(formData);
    }
  }


  render() {

    return (

      <div id="div_btn_Call" className='div_key_button' >

        <button id="btn_Call" className={btn_key_control_secondary}>

          < div className="pulse_container" >
            <img id="btn_call" src={call_icon} alt={"Call AI"} className="icon_button" style={{ zIndex: "1000" }} title={"Call AI"}
              onClick={btn_Start_Stop_Recording_onClick}
              onContextMenu={(e) => e.preventDefault()} // Get rid of image-related context menu
            />


            <div id="pulse1" className="pulse-bg pulse-small" style={{ display: "none", zIndex: "1" }}></div>
            <div id="pulse2" className="pulse-bg-2 pulse-small" style={{ display: "none", zIndex: "1" }}></div>
          </div >
        </button>
        <label id="lbl_call_AI" className='label_button_title'>Call AI </label>
      </div>




    );
  }
}
//<input type="file" accept=".wav" onChange={this.handleFileChange} />

async function btn_Start_Stop_Recording_onClick() {
  log("btn_Start_Stop_Recording_onClick");
  if (app_state.audio_recording) btn_StopRecording_onClick();
  else
    btn_StartRecording_onClick();
};

async function btn_StartRecording_onClick() {
  //  if(recording) return;
  log("btn_StartRecording_onClick");
  Google_Analytics_Event("Main page", "Start recording clicked");
  document.getElementById("lbl_call_AI").innerText = "Hang up";

  try {
    await turn_on_mic();
    animation(true);
    btn_Pause_onClick(); // pause all activities

    log("Started recording");
  } catch (error) {
    console.error('Error accessing microphone:', error);
  }
  translateWord();
};

export async function turn_on_mic() {
  await MP3recorder.start();
  app_state.audio_recording = true;
  stop_Rec_In_X_seconds();
}

export async function turn_off_mic() {
  app_state.audio_recording = false;
  MP3recorder.stop();
}

export async function btn_StopRecording_onClick() {
  log("btn_StopRecording_onClick");

  document.getElementById("lbl_call_AI").innerText = "Call AI";
  await animation(false);

  MP3recorder.stop().getMp3().then(([buffer, blob]) => {
    sendRecording(blob);
  }).catch((e) => {
    alert('We could not retrieve your message');
    console.log(e);
  });

  // log("clearTimeout");
  clearTimeout(recordingTimer); // Clear any existing timer

  app_state.audio_recording = false;
  log("Stopped recording");
}

async function sendRecording(blob) {
  // return;
  // if (!blob)
  //   blob = new Blob(audioData, { type: 'audio/' + audio_format });

  var formData = new FormData();
  formData.append('file', blob, 'recording.' + audio_format);

  // downloadRecording(blob);

  // let messages = [];
  let messages = [
    { "role": "system", "content": "You are a helpful assistant." },
    { "role": "system", "content": "Please provide concise responses not longer than " + max_ai_response_length + " symbols." },
  ];
  // messages.push({ "role": "system", "content": "The user is interested in the " + app_state.word_type + " \"" + app_state.expression + "\"" })
  // messages.push({ "role": "system", "content": "The user might use the " + app_state.word_type + " \"" + app_state.expression + "\"" })
  messages.push({ "role": "system", "content": "I might later use the " + app_state.word_type + " \"" + app_state.expression + "\"" })
  if (should_praise_user()){
    messages.push({ "role": "system", "content": get_praise_for_studying() });
    balloons(); //congratulation
  }
  messages.push({ "role": "system", "content": "After your response, please ask a follow up question, using the " + app_state.word_type + " " + app_state.expression + " is mandatory" })
  if (app_state.last_user_request) messages.push({ "role": "user", "content": app_state.last_user_request });
  if (app_state.AI_response) messages.push({ "role": "system", "content": app_state.AI_response });

  setVoices();

  let metadata = {
    messages: messages,
    model: open_ai_chat_model,
    request_type: request_types.audio_to_audio,
    max_response_length: max_ai_response_length,
  };
  metadata = add_tts_parameters_to_json(metadata);
  metadata = JSON.stringify(metadata);
  formData.append('metadata', metadata);

  await ask_AI_and_pronounce(formData);
  // await askAI_voice_new(formData);

  // await askAI_voice2("voice", "POST", formData);
}

async function animation(start) {
  let display = 'none';
  if (start) {
    document.getElementById("btn_call").src = call_hang_up_icon;
    // await wait(500); //delay the animation to wait until the mic is on [ms] -> lose some recording time
    display = 'block';
  }
  else {       //stop recording
    document.getElementById("btn_call").src = call_icon;
  }

  document.getElementById("pulse1").style.display = display;
  document.getElementById("pulse2").style.display = display;
}

async function downloadRecording(blob) {
  // if (!blob)
  //   blob = new Blob(audioData, { type: 'audio/' + audio_format });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.style = 'display: none';
  a.href = url;
  a.download = 'recording.' + audio_format;
  a.click();
  URL.revokeObjectURL(url);
  log("Download recording");
};