import React from 'react';
import back from "../../images/back.svg";
import {Spinner} from "../elements/spinner";
import {JWT} from "../elements/google_sso_login";
import {check_errors_in_response, sendRequest} from "../elements/request";
import {delay, log, showSubPage} from "../elements/helpers";
import Payment_Detailed_Description from "../payment/detailed_description";
import Refund_Policy from "../policies/refund";
import Delivery_Policy from "../policies/delivery";
import Contact_Info from "../payment/contacts";
import Log_in_label from "../elements/log_in_label";
import Checkout_form from "../payment/2Checkout_form";
import {Feedback_Form} from "../elements/feedback_form";
import Subscription_Info, {get_subscription} from "../elements/subscription_info";


export let form_loaded = false;
let TwoCheckout_feature = true;
let transaction_Failed = false;
let subscription_monitoring_allowed = false;

// Product price and description
export const subscription_price = 2.99;
export const words_per_subscription = 100;
const product_title = "Study " + words_per_subscription + " words just for\n" + "$" + subscription_price;
const product_description = "$" + subscription_price + " package includes up to " + words_per_subscription + " words and valid for 1 month*";

export default class Payment extends React.Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
        window.Payment = this;
    }

    render() {

        const {loading} = this.state;
        // log("JWT: " + JWT);

        return (
            <div id="div_payment" className="div_sub_page">

                <div className="div_settings_header">
                    <img className="button button_back" src={back} onClick={btnBack_onClick}/>
                    <label className="settings_header">Payment</label>
                </div>

                {get_Failed_Transaction_JSX()}

                {loading ? <div className="div_vertical" style={{marginTop: "40px"}}><Spinner/></div> : <div/>}

                {/* The user is logged in */}
                {!loading && JWT ?
                    <div>
                        <Subscription_Info/>
                        {/*{get_subscription_info_JSX()}*/}
                        {get_Product_info_JSX()}
                        {get_Payment_Form_JSX()}
                        {get_Policies_and_Detailed_product_info_JSX()}
                    </div>
                    :
                    <div>
                        <br/><br/><br/><br/>
                        <Log_in_label/>
                        {get_Pricing_JSX()}
                        {get_Policies_and_Detailed_product_info_JSX()}
                    </div>
                }
            </div>
        )
    }
}


////////////////////// EVENT HANDLERS /////////////////////////////////

export async function Open_Payment_Page(event= null, failed_payment = false){
    // "event" argument is for the button click event
    console.log("Open_Payment_Page. failed_payment: " + failed_payment);

    if(failed_payment)
        transaction_Failed = true;
    else
        transaction_Failed = false;

    await window.Payment.setState({loading: true});
    form_loaded = true;

    // window.Settings.forceUpdate(); // rerender the component
    await showSubPage("div_payment");

    await get_subscription();
    await window.Payment.setState({loading: false});
    subscription_monitoring_allowed = true;
}

const btnBack_onClick = async event => {
    await showSubPage("div_main_content");
    subscription_monitoring_allowed = false;
    // await showSubPage("div_settings");
}

////////////////////// JSX /////////////////////////////////

function get_Product_info_JSX() {
    const JSX =
        <div>
            <label className="label" style={{textAlign: "center", marginTop: "5em"}}>{product_title}</label>
            <label className="label"
                   style={{textAlign: "center", fontWeight: "normal", marginTop: "0.5em", color: "gray"}}>the
                package is valid for 1 month*</label>
        </div>
    return JSX;
}

function get_Policies_and_Detailed_product_info_JSX(){
    let policies = <div/>;
    if(JWT)
        policies =
            <div>
                <Payment_Detailed_Description/><br/>
                <Refund_Policy/><br/>
                <Delivery_Policy/><br/>
                <Contact_Info/><br/>
            </div>

    const JSX =
        <div style={{textAlign: "justify", color: "gray", fontWeight: "normal", marginTop: "4em"}}>
            {policies}
        </div>
    return JSX;
}

function get_Pricing_JSX(){
    let JSX = <div/>
    if(JWT)
        JSX =
            <div>
                <br/><br/>
                <label className="label" style={{fontSize: "18 px"}}>Pricing</label>
                <div style={{marginTop: "0.5em"}}>{product_description}</div>
                <div style={{
                    textAlign: "justify",
                    fontWeight: "normal",
                    marginTop: "1em"
                }}>
                    <Payment_Detailed_Description/>
                </div>
            </div>
    return JSX;
}

function get_Payment_Form_JSX() {
    if(TwoCheckout_feature)
        return <Checkout_form/>
}

function get_Failed_Transaction_JSX(){
    let failed_transaction = <div/>

    if(transaction_Failed)
        failed_transaction =
            <div>
                <br/>
                <label style={{color:"red"}}>The payment was canceled for some reason</label><br/>
                <label style={{color:"gray"}}>Let us know if you have any questions</label>
                <Feedback_Form/>
            </div>

    return failed_transaction;
}

////////////////////// OTHER /////////////////////////////////

export async function run_Subscription_monitoring() {
    log("run_Subscription_monitoring - Start")
    let max_waiting_time = 3 * 60; // sec (monitoring the subscription state for 3 minutes)
    let start_time = Date.now();
    while (true) {

        await get_subscription();
        await window.Payment.setState({loading: false});

        await delay(5); // wait 1s

        // Stop attempts after max duration, sec
        let processing_time_sec = (Date.now() - start_time) / 1000;

        if (processing_time_sec > max_waiting_time || !subscription_monitoring_allowed) {
            log("run_Subscription_monitoring - Stop")
            return;
        }
    }
}
