// Returns: True - no errors, False - errors
import {debug_mode} from "../app_settings";
import {log, please_log_in_phrase, showNotification} from "./helpers";
import {stop_Activities_reset_Controls} from "../main_page";
import {Open_Payment_Page} from "../sub_pages/payment";
import {logOut, setJWT_value} from "./google_sso_login";

const min_Sound_Buffer_Size = 500;

export async function check_errors_in_response(response, check_type = "all") {
    //OUTPUT: False - not valid || True - valid
    log("check_response_errors. check_type: " + check_type);
    // if (debug_mode) console.log(response);

    if (!response) {
        console.error("Response is not valid!");
        log("Response: " + response);
        return false;
    }

    if (!response.data) {
        if (debug_mode) console.error("No data in the response!");
        return false;
    }

    let data_with_error;

    // Process responses from TTS (received in ArrayBuffer format)
    if(response["config"]["responseType"] === "arraybuffer" && response.data.byteLength < min_Sound_Buffer_Size){
        log("response_config_responseType = arraybuffer");
        data_with_error = get_error_JSON(response);
    }
    else
        data_with_error = response.data["error"];

    if (!data_with_error) {
        // log("No error message in the response!");
        return true;
    }

    switch (data_with_error) {
        case "No settings":
            log("No settings error")
            break;

        case "Please buy a package":
            log("Please buy a package error")
            if (check_type === "ignore_subscription_error") {
                // Needed to check the response about payment (subscription is not valid)
                return true; //valid response
            }

            if (debug_mode) console.error("Error -> Stop podcast")
            showNotification(response.data["error"]);
            await stop_Activities_reset_Controls();
            await Open_Payment_Page();
            break;

        case "User authentication error":
            console.error("User authentication error");
            // setJWT_value(null);
            logOut();
            showNotification(please_log_in_phrase);
            return false; // request is unauthorized
            break;

        default:
            if (debug_mode) console.error("Undefined error in the response!");
            break;
    }

    return true; //valid response
}


function get_error_JSON(response) {
    try {
        let data_with_error = String.fromCharCode.apply(null, new Uint8Array(response.data)); //ArrayBuffer to String
        data_with_error = JSON.parse(data_with_error);
        data_with_error = data_with_error["error"];
        log("data_with_error: " + data_with_error);
        return data_with_error;
    }
    catch (err){
        console.error("get_error_JSON. " + err);
    }
}

