import React from "react";

export default class Refund_Policy extends React.Component {

    render() {
        return (
                <div>
                    <b>Refund Policy</b><br/><br/>
                    We offer a full money-back guarantee for all purchases made on our website. If you are not satisfied
                    with the product that you have purchased from us, you can get your money back no questions asked.
                    You are eligible for a full reimbursement within 5 calendar days of your purchase. Just send us an
                    email with the email address you used for the registration.
                    After the 5-day period you will no longer be eligible and won't be able to receive a refund. If you
                    have any additional questions or would like to request a refund, feel free to contact us.<br/><br/>
                </div>
        )
    }
}
