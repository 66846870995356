import React from 'react';
import {words_per_subscription} from "../sub_pages/payment";

export default class Payment_Detailed_Description extends React.Component {

    render() {

        return (
            <div>
                *The app uses multiple 3rd party applications (APIs) for translation, text-to-speech
                conversion, text generation, etc. The APIs are costly. In order to let the user learn a
                word we need to call the APIs about 11 times. That is why the exact
                number of words you can learn with 1 package depends on how you use the app. Every time the app makes an
                API call 1 credit is consumed.
                {<br/>}{<br/>}
                Example 1
                {<br/>}
                Bob buys a {words_per_subscription}-word package and listens to podcasts about words one by one without
                interruptions. Bob will be able to learn {words_per_subscription} words.
                {<br/>}{<br/>}
                Example 2
                <br/>
                Alice buys a {words_per_subscription}-word package and listens to podcasts but pauses and stops podcasts every
                now and then. Alice also listens to podcasts about words she learned previously. Alice will
                be able to learn about {words_per_subscription/2} new words and repeat about {words_per_subscription/2} words she learned before.
                <br/>
                <br/>
            </div>
        )
    }
}
