import React from 'react';

export default class Landing_page extends React.Component {

    render() {


        return (

            <div id="div_landing_page" className="div_landing_page">


                <div style={{textAlign: "center"}}>
                    <div className="video-container">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rX5deH2WanE"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                    </div>
                    <br/>
                    <br/>
                    <label className="label_landing_text">Learn English words and expressions faster and easier
                        with English
                        AI</label>
                    <br/>
                    <br/>
                </div>

                <label className="label_header">Study the most common words</label>
                <label className="label_landing_text"> first </label>
                <br/>
                <br/>

                <label className="label_header">Have fun</label>
                <label className="label_landing_text"> listening to hilarious stories with the words you have
                    chosen</label>
                <br/>
                <br/>

                <label className="label_header">Practice speaking</label>
                <label className="label_landing_text"> by talking with AI</label>
                <br/>
                <br/>

                <label className="label_header">Learn the word context</label>
                <label className="label_landing_text"> with the help of diverse examples and collocations</label>
                <br/>
                <br/>

                {/* <label className="label_header">Practice</label>
                <label className="label_landing_text"> by coming up with your own sentences using provided
                    examples</label>
                <br/>
                <br/> */}

            </div>
        )
    }
}

