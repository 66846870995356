import React, { useEffect } from "react";
import Settings from "./components/sub_pages/settings";
import Menu_Button from "./components/menu/menu_button";
import { ai_buttons, links_practice, links_images } from "./components/buttons/link_button";
import ReactGA from 'react-ga4';
import Vocabulary from "./components/sub_pages/vocabulary";
import App_settings from "./components/app_settings";
import PopularWords from "./components/sub_pages/popular_words";
import NewWords from "./components/sub_pages/new_words";
import Gallery from "./components/elements/gallery";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Payment from "./components/sub_pages/payment";
import Translation_block from "./components/elements/translation_block";
import './scss/payment.scss';
import './scss/notifications.scss';
import './scss/landing_page.scss';
import './scss/App.scss';
import Landing_page from "./components/elements/landing_page";
import Menu from "./components/sub_pages/menu";
import Policies from "./components/sub_pages/policies";
import Statistics from "./components/elements/statistics";
import Menu_User from "./components/menu/menu_user";
import Log_in_label from "./components/elements/log_in_label";
import { btn_login_main } from "./components/elements/google_sso_login";
import Contact_us from "./components/sub_pages/contact_us";
import Bottom_Button from "./components/buttons/bottom_button";
import Radial_button from "./components/buttons/radial_button";
import Menu_Mode from "./components/menu/menu_mode";
import Test_Env_Warning from "./components/elements/test_env_warning";
import { Google_Analytics_Event } from "./components/elements/helpers";
import Main_page from "./components/main_page";
import Bottom_Menu from "./components/menu/menu_bottom";

// Google Analytics
// const TRACKING_ID = "UA-267250282-1";
const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
ReactGA.initialize(TRACKING_ID);

function App() {

    // Do this after render
    useEffect(() => { Google_Analytics_Event("Main", "App opened"); }, []);

    return (
        <div className="App">


            {/* Top Menu*/}
            <div id="div_top_menu" className="div_top_menu" >
                <div className="div_horizontal" style={{ justifyContent: "space-between", alignItems: "center" }}>
                    <div className="div_horizontal" style={{ alignItems: "center" }}>
                        <Menu_User />
                        <Statistics />
                    </div>

                    <div className="div_horizontal" style={{ justifyContent: "flex-end", alignItems: "center" }}>
                        {/*<ModeButton/>*/}
                        <Menu_Mode />
                        <Menu_Button />
                    </div>
                </div>
            </div>

            <div className="center_column">

                {/* Pages and Notifications */}
                <App_settings /> {/* Needed for initialization */}
                <Settings />
                <Menu />
                <Policies />
                <Vocabulary />
                <NewWords />
                <PopularWords />
                <Payment />
                <ToastContainer />
                <Contact_us />

                <div style={{ marginTop: "3em" }} />
                <div id="div_main_content" style={{ position: "relative" }}>

                    <Test_Env_Warning />

                    {/* Main_page controls */}
                    <Log_in_label id={btn_login_main} />
                    <Main_page />
                    <Translation_block />
                    <Landing_page />

                    <div className="div_horizontal">
                        <div id="div_radial_buttons" className="div_radial_buttons">
                            <Radial_button id={links_images} />
                            <Radial_button id={ai_buttons} />
                            <Radial_button id={links_practice} />
                        </div>
                    </div>
                    {/* Space for the radial buttons*/}
                    <div style={{ height: "150px" }} />
                    <Gallery id="gallery" />
                    <Bottom_Button />
                    {/* Additional space at the bottom for mobile devices */}
                    <div style={{ height: "70px" }} />
                </div>

            </div>

            {/* Bottom Menu*/}
            {/* <Bottom_Menu/> */}
        
        </div>
    )
}

export default App;

