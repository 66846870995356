import React from 'react';
import dictionary_icon from "../../images/Dictionary_mode_482_v2.png";
import brush_up_icon from "../../images/Brush_up_mode_482_v3.png";
import kids_icon from "../../images/Kids_mode_482_v2.png";
import {btnOpenSettings_onClick, settings} from "../sub_pages/settings";
import {JWT} from "../elements/google_sso_login";

export default class ModeButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {"random_value": null};
        window.ModeButton = this;
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="div_menu">
                {JWT ?
                    getButton_JSX(btnOpenSettings_onClick)
                    :
                    <div/>
                }
            </div>
        )
    }
}



export function getButton_JSX(event_handler){
    let icon;
    let title;

    switch (settings.app_mode.value) {
        // case settings.app_mode.modes.Default.name:
        //     icon = classic_mode_icon;
        //     title = "Classic mode";
        //     break;
        case settings.app_mode.modes.Brush_up.name:
            icon = brush_up_icon;
            title = settings.app_mode.modes.Brush_up.title + " mode";
            break;
        case settings.app_mode.modes.Dictionary.name:
            icon = dictionary_icon;
            title = settings.app_mode.modes.Dictionary.title + " mode";
            break;
        case settings.app_mode.modes.Kids.name:
            icon = kids_icon;
            title = settings.app_mode.modes.Kids.title  + " mode";
            break;
    }

    let button = <img src={icon} alt={title} className="icon_mode" title={title} onClick={event_handler}/>
    return button;
}
