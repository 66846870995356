import React from 'react';
import {
    guest_says, playIntro, playSound, setVoices,
    sound_is_paused, speech_audio, TTS_Pause_Playing, TTS_Reset, TTS_Resume_Playing
} from "./elements/tts";
import { saveToStorage } from "./elements/storage";
import List_icon from "../images/list_v2.png";
import Play_icon from "../images/Play_482.png";
import Stop_icon from "../images/Stop_512.png";
import Pause_icon from "../images/Pause_225.png";
import Next_icon from "../images/Next_482_v2.png";
import {
    btn_Open_Vocabulary_onClick,
    loadVocabulary,
    putOffWord,
    putOff_Word_DB,
    save_user_word,
    setNextWord, word_was_practiced
} from "./sub_pages/vocabulary";
import { request_and_validate, stopRequests } from "./elements/request";
import { loadImages, removeImages } from "./elements/gallery";
import { check_Auth_Locally } from "./elements/google_sso_login";
import { Spinner } from "./elements/spinner";
import {
    delay, Google_Analytics_Event, is_iOS,
    is_multiple_words, JSON_to_FormData, log,
    log_time,
    removeQuotes,
    setDisplayValue,
    showWord_Frequency, startWatchdog,
    translate
} from "./elements/helpers";
import { container_id_prefix, links_images } from "./buttons/link_button";
import {
    guest_finished,
    host_finished, podcast_is_paused_or_pausing,
    phrase_was_finished,
    reset_podcast,
    runPodcast, podcast_or_story_is_pausing, podcast_or_story_are_ongoing
} from "./elements/podcast";
import { app_state, settings } from "./sub_pages/settings";
import { give_example, open_ai_chat_model } from "./elements/APIs";
import {
    set_Text_to_Translate,
    set_translation_control_status, translation_status,
    translation_statuses
} from "./elements/translation_block";
import { show_statistics } from "./elements/statistics";
import Auto_Complete_Input, { setInput_Word } from "./elements/autocmoplete";
import { btnAddPopularWords_onClick } from "./sub_pages/popular_words";
import AudioRecorder from "./elements/AudioRecorder";
import Talking_head, { replay_last_response } from "./elements/talking_head";
import { debug_mode } from "./app_settings";
import { getQuestion } from "./resources/phrases";

// Variables
export let translation;    // Current word's translation
export let word_type;      // learning word / expression
export let word_types = {
    word: "word",
    expression: "expression"
}
export const btn_key_control_primary = "button_icon button_round button_primary_shadow";
export const btn_key_control_secondary = "button_icon button_round button_secondary_shadow";

// MODE
export let last_word_from_vocabulary; // original word from the vocabulary
let last_translated_word;

export default class Main_page extends React.Component {

    constructor(props) {
        super(props);
        window.Main_page = this;
    }

    render() {
        let btn_next;
        if (settings.app_mode.value === settings.app_mode.modes.Brush_up.name || app_state.old_word_mode || settings.app_mode.value === settings.app_mode.modes.Kids.name) {
            btn_next =
                <div id="div_btn_Next_Word" className='div_key_button'>
                    <button id="btn_Next_Word" className="button_icon button_round button_secondary_shadow" title="Next word">
                        <img src={Next_icon} onClick={btn_Next_Word_onClick} className="icon_button" alt="Next word" />
                    </button>
                    <label className='label_button_title'>Next</label>
                </div>
        }


        return (

            <div>
                <Talking_head />
                <form id="div_podcast_controls" className="div_podcast_controls">

                    <div className="div_vertical">

                        <Auto_Complete_Input />
                        <label id="lbl_word_translation" style={{ color: "gray", fontSize: "22px", minHeight: "1.3em" }}></label>

                        <div id="div_play_controls" className="div_horizontal div_play_controls">

                            <div id="div_btn_Start" className='div_key_button'>
                                <button id="btn_Start" className={btn_key_control_primary} type="submit" title="Example">
                                    <img src={Play_icon} onClick={btn_Play_onClick} className="icon_button" alt={"Play"} />
                                </button>
                                <label className='label_button_title'>Example</label>
                            </div>

                            <div id="div_btn_Stop" className='div_key_button' style={{ display: "none" }}>
                                <button id="btn_Stop" className={btn_key_control_secondary} title="Stop">
                                    <img src={Stop_icon} onClick={btn_Stop_onClick} className="icon_button" alt={"Stop"} />
                                </button>
                                <label className='label_button_title'>Stop</label>
                            </div>

                            <AudioRecorder />

                            {btn_next}

                        </div>
                    </div>

                </form >
            </div >
        )
    }
}

////////////////////// EVENT HANDLERS /////////////////////////////////
export async function btn_Play_onClick() {
    log("btnPlay_onClick");

    // await delay(4);
    // await playSound('https://eng-ai-public-s3-bucket.s3.us-west-2.amazonaws.com/upbeat_4sec.mp3');
    // return;

    if (await resume_playing()) return;
    if (!check_Auth_Locally()) return;

    if (app_state.audio_suspended) {
        await replay_last_response();
        return;
    }

    // Parse the expression
    if (!await parseUserWords(document.getElementById('inp_learning_phrase').value)) { // can't parse the word
        log("can't parse the word");
        app_state.state = app_state.states.none;
        update_Controls();
        return;
    }

    if (settings.app_mode.value === settings.app_mode.modes.Dictionary.name) {
        await run_dictionary_mode();
        return;
    }
    if (settings.app_mode.value === settings.app_mode.modes.Kids.name) {
        await run_kids_mode();
        return;
    }

    await run_podcast_mode();

    Google_Analytics_Event("Main", "Play onClick");
}

export async function btn_Next_Word_onClick() {
    log("btn_Next_Word_onClick");

    app_state.state = app_state.states.none;
    const word_to_put_off = app_state.expression;

    await putOffWord(word_to_put_off, false); // Do not update DB to speed up UI (part 1)

    //Show popular words if there are no words
    const next_word_exist = await setNextWord();
    if (!next_word_exist) {
        btnAddPopularWords_onClick();
        return;
    }

    await putOff_Word_DB(word_to_put_off); // Update DB asap (part 2), otherwise the DB doesn't get updated

    showWord_Frequency();
    show_statistics(); // shouldn't go at the end, otherwise it doesn't work (request interruption)

    // log(settings.app_mode.value)
    if (settings.app_mode.value === settings.app_mode.modes.Kids.name)// Kids mode
        await btn_Play_onClick();
    else {
        if (!is_iOS()) guest_says(app_state.expression);
        await hide_old_information();

        if (settings.auto_word_example.value)
            await give_example();
    }

    translateWord();
    await loadVocabulary(); // Load vocabulary to speed up the function next time
}

export async function btn_Pause_onClick() {
    log("btn_Pause_onClick")

    switch_state_ongoing_to_pausing();
    update_Controls();
    await stop_Activities(true);

    // log("btn_Pause_onClick. app_state.state 1: " + app_state.state)
    switch_state_pausing_to_paused();

    // log("btn_Pause_onClick. app_state.state 2: " + app_state.state)
    update_Controls();
}

export function btn_Stop_onClick() {
    stop_Activities_reset_Controls();
}

////////////////////// STOP / PAUSE /////////////////////////////////

export async function criticalError(error) {
    console.error(error);
    await stop_Activities_reset_Controls();
}

export async function stop_Activities_reset_Controls() {
    // Cancel all requests and threads, stop podcast
    log("forcedStopPodcast start");

    // switch_state_ongoing_to_pausing();
    app_state.state = app_state.states.podcast_pausing; //needed to stop play_sound timer (delay)

    await stop_Activities();
    reset_podcast();
    await TTS_Reset();

    app_state.state = app_state.states.none;
    update_Controls();

    log("forcedStopPodcast end");
    // app_state.state = app_state.states.none;
}

export async function stop_Activities(paused = false) {
    log("stop_Activities");
    await stopRequests();

    update_Controls();

    await TTS_Pause_Playing();
    const waiting_time = 0.1; // [sec]
    await new Promise(res => setTimeout(res, waiting_time * 1000));
    // await delay(4);
    await TTS_Pause_Playing();
}

function switch_state_ongoing_to_pausing() {
    if (app_state.state === app_state.states.podcast_ongoing)
        app_state.state = app_state.states.podcast_pausing;

    if (app_state.state === app_state.states.story_ongoing)
        app_state.state = app_state.states.story_pausing;
}

function switch_state_pausing_to_paused() {
    if (app_state.state === app_state.states.podcast_pausing)
        app_state.state = app_state.states.podcast_paused;

    if (app_state.state === app_state.states.story_pausing)
        app_state.state = app_state.states.story_paused;
}

////////////////////// RESUME /////////////////////////////////

async function resume_playing() {
    if (app_state.state !== app_state.states.none) {
        log("sound_is_paused. app_state.state: " + app_state.state);

        // if(!await start_Media()) return;
        if (app_state.state === app_state.states.story_paused)
            await resume_story();

        if (app_state.state === app_state.states.podcast_paused)
            await resume_podcast();

        return true;
    }
    else
        return false;
}

async function resume_story() {
    app_state.state = app_state.states.story_ongoing;

    update_Controls();
    await resume_audio();

    if (app_state.state === app_state.states.story_ongoing &&
        !sound_is_paused()) {
        // story was finished
        app_state.state = app_state.states.none;
        update_Controls();
    }
}

async function resume_podcast() {
    log("resume_podcast");
    app_state.state = app_state.states.podcast_ongoing;
    update_Controls();

    let audio_was_resumed = await resume_audio();
    if (audio_was_resumed && phrase_was_finished()) {
        // finished the phrase
        if (app_state.question_state === app_state.question_states.host_finished) //finished the host and guest phrases -> next question
        {
            log("resume_podcast. next question");
            await guest_finished();
        }
        else {
            log("resume_podcast. host_finished");
            await host_finished();
        }
    }

    await runPodcast();

}

async function resume_audio() {
    log("resume_audio");

    if (!speech_audio.duration || !speech_audio.currentTime) return false; // no audio to play
    await TTS_Resume_Playing();
    await delay(speech_audio.duration - speech_audio.currentTime);
    return true;
}

////////////////////// EXPORTED FUNCTIONS /////////////////////////////////

export async function startVocabularyMode(word_obj) {
    log("startVocabularyMode");

    // reset AI answers as we start with a new word
    app_state.AI_response = null;
    app_state.last_user_request = null;

    stop_Activities_reset_Controls();
    document.getElementById('lbl_word_translation').innerText = ""; //Clear translation, space to keep the element visible
    last_word_from_vocabulary = word_obj["word"];
    reset_podcast();
    await parseUserWords(last_word_from_vocabulary, true);
    saveToStorage("expression", app_state.expression);
}

export async function parseUserWords(input, update_input_ctrl = false) {
    log("parseUserWords: " + input);
    input = input.trim();

    let max_length = 500;
    if (input.length > max_length) {
        input = input.substring(0, max_length);
        console.log("Max string length is " + max_length + ". The input has been cut");
    }

    if (!input) {
        console.error("Couldn't parse the learning phrase!")
        update_Controls();
        return false;
    }

    if (update_input_ctrl === true)
        setInput_Word(input);
    // document.getElementById('inp_learning_phrase').value = input;

    // await setNewExpression(input);
    await setExpression(input);
    return true;
}

////////////////////// MODES /////////////////////////////////

async function run_podcast_mode() {
    log("run_podcast_mode");

    try {
        app_state.state = app_state.states.podcast_ongoing;
        update_Controls();

        // if (!is_iOS()) { // Skip for mobile Safari to save time
        showWord_Frequency();
        show_statistics();
        loadImages();
        // }

        // if (app_state.podcast_question_number === 0) await translateWord(app_state.expression); // First question -> Translate the word
        if (app_state.podcast_question_number === 0) translateWord(app_state.expression);

        await save_user_word(removeQuotes(app_state.expression));
        await runPodcast();
        putOffWord(app_state.expression);

        if (app_state.state === app_state.states.podcast_ongoing) { // check podcast is not paused or stopped
            log("btnStart_onClick - reset_podcast");
            reset_podcast();
        }

        // if (is_iOS()) { // Load for mobile Safari if we have time
        //     show_statistics();
        //     loadImages();
        // }

        log("stop podcast_mode");
    }
    catch (e) {
        log(e);
    }
}

async function run_dictionary_mode() {
    log("run_dictionary_mode");

    app_state.state = app_state.states.loading_data;
    update_Controls();
    await translateWord();
    await loadImages();
    await save_user_word(removeQuotes(app_state.expression));

    app_state.state = app_state.states.none;
    update_Controls();
}

async function run_kids_mode() {
    log("run_kids_mode");

    app_state.state = app_state.states.loading_data;
    update_Controls();

    await loadImages();
    show_statistics()

    app_state.state = app_state.states.none;
    update_Controls();
}

////////////////////// OTHER /////////////////////////////////

export function setExpression(value, save_to_storage = true) {
    log("setExpression: " + value);
    app_state.state = app_state.states.none; // reset the resume sound
    reset_podcast();
    value = value.trim();

    if (app_state.expression === value) return; // the expression is already in use

    resetWordTranslation();

    app_state.expression = value;
    app_state.old_word_mode = word_was_practiced(app_state.expression);

    if (is_multiple_words(app_state.expression)) word_type = word_types.expression;
    else word_type = word_types.word;

    if (app_state.expression && save_to_storage) saveToStorage("expression", app_state.expression);
}

async function hide_old_information() {
    await set_Text_to_Translate(null);
    removeImages();
    setDisplayValue(container_id_prefix + links_images, "none"); //remove top links
}

export function update_Controls(in_app_state = null) {
    if (in_app_state)
        app_state.state = in_app_state;
    log("update_Controls. app_state.state: " + app_state.state)
    setDisplayValue("div_landing_page", "none"); //Hide the landing page

    if (podcast_or_story_is_pausing()) { // podcast is being stopped, showing spinners
        // log("show_controls - podcast is being stopped");
        // setDisplayValue("spr_ongoing_podcast_center", "inline-block");
        // setDisplayValue("spr_ongoing_podcast_right", "inline-block");
        // setDisplayValue("spr_ongoing_podcast_left", "inline-block");
        // setDisplayValue("div_btn_Stop", "none");
        // setDisplayValue("div_btn_Start", "none");
        // setDisplayValue("btn_Pause_Bottom", "none");
        // setDisplayValue("div_btn_my_vocabulary", "none");
        if (translation_status !== translation_statuses.Loaded)
            set_translation_control_status(translation_statuses.Reset);
        return;
    } else { //podcast has been stopped or ongoing -> hiding spinners
        // log("show_controls - podcast has been stopped or ongoing");
        setDisplayValue("spr_ongoing_podcast_right", "none");
        setDisplayValue("spr_ongoing_podcast_left", "none");
        setDisplayValue("spr_ongoing_podcast_center", "none");
    }

    if (podcast_or_story_are_ongoing() ||
        app_state.state === app_state.states.loading_data ||
        app_state.state === app_state.states.reading_text) { // starting podcast / story or resuming from a pause -> stop / pause buttons
        // log("sound_is_paused()", sound_is_paused());
        setDisplayValue("div_btn_Start", "none");
        // setDisplayValue("btn_Pause", "inline-block");
        setDisplayValue("div_btn_Stop", "flex");
        // setDisplayValue("btn_Pause_Bottom", "inline-block");
        // setDisplayValue("div_btn_Next_Word", "none");
        if (!sound_is_paused() && app_state.state !== app_state.states.reading_text) // resuming from pause or re-reading the text
            setDisplayValue("div_text_and_translation", "none");
    } else { // finishing podcast
        // log("show_controls - finishing podcast");
        setDisplayValue("div_btn_Start", "flex");
        // setDisplayValue("btn_Pause", "none");
        setDisplayValue("div_btn_Stop", "none");
        // setDisplayValue("btn_Pause_Bottom", "none");
        setDisplayValue("div_btn_my_vocabulary", "flex");
        if (settings.app_mode.value !== settings.app_mode.modes.Dictionary.name ||
            settings.app_mode.value === settings.app_mode.modes.Kids.name &&
            app_state.state !== app_state.states.podcast_paused &&
            app_state.state !== app_state.states.story_paused)
            setDisplayValue("div_btn_Next_Word", "flex");
    }
}

export async function translateWord() {
    log("translateWord");

    const text = app_state.expression;
    if (!text) return; // no text to translate
    if (last_translated_word === text) return; //avoid translating the same word
    if (app_state.state === app_state.states.podcast_pausing) return;

    var element = document.getElementById('lbl_word_translation');
    element.style.filter = 'blur(6px)';
    element.addEventListener('click', removeBlure); // Remove blur if the user clicks the translation

    translation = await translate(text);
    if (!translation) return;
    document.getElementById('lbl_word_translation').innerText = removeQuotes(translation);
    last_translated_word = text;
}

async function removeBlure(event) {
    // log("removeBlure");
    var element = event.target;
    if (element.style.filter === '')
        element.style.filter = 'blur(6px)';
    else
        element.style.filter = '';
}

function resetWordTranslation() {
    document.getElementById('lbl_word_translation').innerText = "";
}
