import React from "react";
import {log, showNotification, showSubPage} from "../elements/helpers";
import back from "../../images/back.svg";
import {debug_mode} from "../app_settings";
import {btnOpenSettings_onClick, loadSettingsFromDB} from "./settings";
import {Open_Payment_Page} from "./payment";
import {btnOpenTerms_onClick} from "./policies";
import {btnOpenContactUs_onClick} from "./contact_us";
import {btn_Open_Vocabulary_onClick} from "../sub_pages/vocabulary";

export default class Menu extends React.Component {

    render() {
        return (
            <div id="div_menu" className="div_sub_page">
                <div className="div_settings_header">
                    <img className="button button_back" src={back} onClick={btnBack_onClick} alt="Back"/>
                    <label className="settings_header">Menu</label>
                </div>

                <div className="div_vertical">
                    <button className="button_menu" onClick={btn_Open_Vocabulary_onClick}>My vocabulary</button>
                    <button className="button_menu" onClick={btnOpenSettings_onClick}>Settings</button>
                    <button className="button_menu" onClick={Open_Payment_Page}>Pricing</button>
                    <button className="button_menu" onClick={btnOpenTerms_onClick}>Policies</button>
                    <button className="button_menu" onClick={btnOpenContactUs_onClick}>Contact us</button>
                </div>
            </div>
        )
    }
}

export const btnOpenMenu_onClick = async event => {
    log("btnOpenMenu_onClick");
    // await window.Settings.setState({loading: true});
    // window.Settings.forceUpdate(); // rerender the component
    await showSubPage("div_menu");
    // await loadSettingsFromDB();
    // await window.Settings.setState({loading: false});
}

const btnBack_onClick = async event => {
    await showSubPage("div_main_content");
}

