import React from "react";

export default class Terms_of_Service extends React.Component {

    render() {
        return (
            <div>
                <b>Terms of Service</b><br/><br/>
                The user acknowledges and agrees that the Services provided and made available through our
                website and applications, which may include some mobile applications.<br/><br/>
                Due to the global nature of the internet, through the use of our network you hereby agree to
                comply with all local rules relating to online conduct and that which is considered acceptable
                Content. <br/><br/>
                The user herein agrees not to replicate, duplicate, copy, trade, sell, resell nor exploit for
                any commercial reason any part of the website. <br/><br/>
                The use of the services and software are at the sole risk by you, Our services and software
                shall be provided on an "as is" and/or "as available" basis. <br/><br/>
            </div>
        )
    }
}
