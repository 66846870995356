import React from 'react'
import '../../scss/gallery.css'
import {app_state, settings} from "../sub_pages/settings";
import {v4 as uuidv4} from "uuid";
import Link_Button, {links_images} from "../buttons/link_button";
import {debug_mode} from "../app_settings";
// import {stopping_podcast} from "../main_page";
import {request_and_validate} from "./request";
import {shuffleArray} from "../resources/phrases";
import {JSON_to_FormData, log} from "./helpers";

let big_picture_source;
let full_screen_view = false;

let images_per_page = 5;
const images_per_response = 10; // Google sends 10 images by default
const preloaded_picture_number = 3;
let response;
let gif_format = false;
let ignore_events = false;
let start_index = 0; // first image in the response to show
let image_links;

export default class Gallery extends React.Component {

    constructor(props) {
        super(props);

        this.state = {"images": [], "start_position": 1};
        window.Gallery = this;
        // window.Gallery.setState({"images": data_urls}) // To Do
    }

    render() {
        if (!settings.show_images.value && settings.app_mode.value !== settings.app_mode.modes.Kids.name) return;

        let gallery;
        if (this.state && this.state.images && this.state.images.length > 0) {
            gallery =
                <>
                    {/* Full screen picture */}
                    <div className={full_screen_view ? "picture full_screen" : "picture"}
                         onClick={() => full_screen(false)}>
                        <img src={big_picture_source}/>
                    </div>

                    <div className="gallery">
                        {this.state.images.map((item, index) => (
                            // return (
                            <div key={uuidv4()}  onClick={() => full_screen(true, item.link)}>

                                <img className="pics" src={gif_format ? item.link : item.thumbnailLink}
                                     style={{width: "100%"}}/>

                            </div>
                        ))
                        }
                    </div>

                    {getPreloadedPictures()}
                </>
        }

        return (
            <>
                {gallery}
                <Link_Button id={links_images}/>
            </>
        )
    }
}

function getPreloadedPictures(){
    // log("getPreloadedPictures");

    if(!window.Gallery.state || !window.Gallery.state.images || window.Gallery.state.images.length < 1) return;

    let pic_number = preloaded_picture_number;
    if (window.Gallery.state.images.length < pic_number) pic_number = window.Gallery.state.images.length;

    let pictures_JSX = [];
    for (let i = 0; i < pic_number; i++) {
        pictures_JSX.push(<img key={uuidv4()} src={window.Gallery.state.images[i].link} style={{display: "none"}} />);
    }
    return pictures_JSX;
}

export async function removeImages(){
    window.Gallery.setState({"images": null});
}

export async function loadImages(new_images = true) { //start_over = load new images
    log("loadImages");
    if (app_state.state === app_state.states.podcast_pausing) return;
    ignore_events = true;

    if (new_images)
        window.Gallery.state.start_position = 1;
    else
        window.Gallery.state.start_position += images_per_page;

    // if (debug) image_links = getTestImages();

    // console.log(start_index + images_per_page)
    if (!response || new_images || start_index + images_per_page > images_per_response) { // use images from previous response or load new
        let funny = Math.random() < 0.3; // 0.1 = 10% probability of getting true
        gif_format = Math.random() < 0.5; // 0.1 = 10% probability of getting true

        if(settings.app_mode.value === settings.app_mode.modes.Kids.name){
            // images_per_page = 1;
            funny = false;
            gif_format = false;
        }

        const metadata = {
            word: app_state.expression,
            start_position: (window.Gallery.state.start_position).toString(),
            funny: (funny).toString(),
            gif: (gif_format).toString()
        };
        start_index = 0;
        response = await request_and_validate("images", "POST", JSON_to_FormData(metadata));
        if(!response) return false;
    }

    // if(!await checkResponse(response)) return false;

    image_links = await getImagesFromResponse(response);
    // if(debug) console.log(response);

    if (!image_links) {
        log("image_links: " + image_links);
        window.Gallery.setState({"images": [], "start_position": 1});
        ignore_events = false;
        return false;
    }

    // console.log(image_links);
    window.Gallery.setState({"images": image_links});

    ignore_events = false;
    return true;
}

async function getImagesFromResponse(response){
    // if (!response) return null;

    log("getImagesFromResponse");
    // log(response);
    // if (!await checkResponse(response)) return null;

    let images_json = response.data;
    if (images_json === "None") images_json = []; // no images

    let image_links = [];
    for (let i = start_index; i < start_index + images_per_page; i++) {
        image_links.push(
            {
                link: images_json["items"][i]["link"],
                thumbnailLink: images_json["items"][i]["link"]
                // thumbnailLink: images_json["items"][i]["image"]["thumbnailLink"]
            }
        );
    }
    start_index += images_per_page;

    return image_links;
}

function getTestImages(){
    let images = test_images;
    return shuffleArray(images);
}

const test_images = [
    {link:"https://images-prod.healthline.com/hlcmsresource/images/AN_images/health-benefits-of-apples-1296x728-feature.jpg",
        thumbnailLink:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSlqymB5wHJzCudVhhdckCq61caSNM7vCa5Ns8M7WeNoyw9mmu9NW-XHx0&s"},

    {link: "https://avatars.githubusercontent.com/u/10639145?s=280&v=4",
        thumbnailLink:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxCmLyMfSMr5IbytndEgn1N7kkLm0v6nEBAkJfLK9vltdQ7wjHbEu3pQ&s"},

    {link: "https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/apple-tv-4k-hero-select-202104_FMT_WHH?wid=940&hei=1112&fmt=p-jpg&qlt=95&.v=1617137945000",
        thumbnailLink:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ4ySQbq5zkWkzkojqfHaVJQIIl_tfah-SCBIAeoPXDg-d0rxHoo5Uqlg&s"},

    {thumbnailLink: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR7nk1taLAvcwx4eWoLGvdse7rUR-aMmQx3Nv1Mr6xYjSF_WkP6lVpGMOY&s",
        link: "https://www.applesfromny.com/wp-content/uploads/2020/06/EmpireNEW.png"},

    {thumbnailLink: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTwl7toevukx5xRlOwhdDXqHUUNGr97a99nsafSkA2AlwODALL1Ffozkzc&s",
        link: "https://healthiersteps.com/wp-content/uploads/2021/12/green-apple-benefits.jpeg"},

    // {thumbnailLink: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQnblvSC-MYzx9TELXTZyyo1_SZ_wmj_lL_Aau4MTCMS8JluxmfUt8_3Q&s",
    //     link: "https://www.applesfromny.com/wp-content/uploads/2020/05/20Ounce_NYAS-Apples2.png"},
    //
    // {thumbnailLink: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQq5rods-2mas2-0Xyof1cApLeiLlRXiD7crY8iaxBW2Flmdv6N4pY_xXCy&s",
    //     link: "https://media.istockphoto.com/photos/red-apple-picture-id184276818?k=20&m=184276818&s=612x612&w=0&h=QxOcueqAUVTdiJ7DVoCu-BkNCIuwliPEgtAQhgvBA_g="},

];

function full_screen(value, img_source = null){
    full_screen_view = value;
    big_picture_source = img_source;

    // if(value === true)


    window.Gallery.setState({"update": true});
}
