import React from "react";
import {addWord} from "./vocabulary";
import back from "../../images/back.svg";
import {showSubPage} from "../elements/helpers";

export default class NewWords extends React.Component {

    componentDidMount() {
        initialize();
    }

    render() {
        return (
            <div id="div_new_words" className="div_sub_page">
                <div className="div_settings_header">
                    <img className="button button_back" src={back} onClick={btnBack_onClick} alt="Back"/>
                    <label className="settings_header">Add words</label>
                </div>

                <form id="frm_new_words" className="div_vertical">

                    <textarea id='txt_new_words' style={{display: "block", width: "100%", marginTop: "2em"}}
                              rows={5} type="input" className="input big_input" placeholder="word 1, word 2," required/>

                    <button id="btn_Add_Words" style={{marginTop: "2em"}} className="button button_vocabulary button_primary button_action_vocab_page"
                            onClick={btnAddWords_onClick} value="submit" type="submit">Add words
                    </button>
                </form>
            </div>
        )
    }
}

function initialize(){
    // Remove default submit to prevent the page reload
    const form = document.getElementById('frm_new_words');
    form.addEventListener('submit', function (e) {
        e.preventDefault();
    });
}

export const btnAddWordsForm_onClick = async event => {
    await showSubPage("div_new_words");
}

const btnAddWords_onClick = async event => {
    // console.log("btnAddWords_onClick")
    let new_words = await parseWords(document.getElementById('txt_new_words').value);
    if(!new_words) return;

    // await addWords(new_words);
    new_words = new_words.filter(word => word); // remove: "", false, 0, null, undefined, NaN
    new_words = new_words.map(word => word.trim()); // remove spaces
    new_words.forEach(word => addWord(word));

    document.getElementById('txt_new_words').value = ""; // Remove text
    await showSubPage("div_vocabulary"); // quit
}

const btnBack_onClick = async event => {
    await showSubPage("div_vocabulary");
}

async function parseWords(input) {
    input = input.trim();

    let max_length = 200000;
    if (input.length > max_length) {
        input = input.substring(0, max_length);
        console.error("Max string length is " + max_length + ". The input has been cut");
    }

    if (!input) {
        console.error("Couldn't parse the learning phrase!")
        return false;
    }

    let regex = /[,;/\\\n]+/; // [] - square brackets matches one of the provided characters, + - matches the preceding item one or more times
    let learning_phrases = input.split(regex);
    // console.log("Number of expressions = " + learning_phrases.length)

    return learning_phrases;
}
